import { EventNote } from '@mui/icons-material';
import { API_BASE_URL, ACCESS_TOKEN, API_WEATHER_URL } from '../constants';

const request = (options) => {
    console.log("URL: " + options.url);
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    console.log("URL: " + options.url);
    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                // console.log("Request " + options.url + " JSON:  " + JSON.stringify(json));
                return json;
            }).catch(function (err) {
                console.log(err + " url: " + options.url);
            })
        );
};

/**This function is to make api request to the weather service with the apikey */
const weatherApiRequest = (options) => {
    console.log("URL: " + options.url);
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
    headers.append('Authorization', 'ApiKey demoApiKey');
    console.log(headers);
    // if (localStorage.getItem(ACCESS_TOKEN)) {
    //     headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    // }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    console.log("URL: " + options.url);
    console.log("options: " + options);
    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                // console.log("Request " + options.url + " JSON:  " + JSON.stringify(json));
                return json;
            }).catch(function (err) {
                console.log(err + " url: " + options.url);
            })
        );
};

/**This function will make a call to the server and redirct to the URL */
const requestRedirect = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    console.log(localStorage.getItem(ACCESS_TOKEN));
    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response => {
            // HTTP 301 response
            return response;
        })
        .catch(function (err) {
            console.info(err + " url: " + options.url);
        });
};
/**these getCFWebUrl and getCFweather url will manage
 * setting the right URL in different envs
 */
function getCFWebUrl() {
    console.log("getCFWebURL");
    console.log("current web env=" + process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'production') {
        return '/chasinfins/web';
    } else {
        return API_BASE_URL;
    }
}

function getCFWeatherUrl() {
    console.log("getCFWeatherURL");
    console.log("current weather env=" + process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'production') {
        console.log('setting url to production..../chasinfins/weather');
        return '/chasinfins/weather';
    } else {
        return API_WEATHER_URL;
    }
}

export function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: getCFWebUrl() + "/user/me",
        method: 'GET'
    });
}

export function login(loginRequest) {
    return request({
        url: getCFWebUrl() + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function signup(signupRequest) {
    return request({
        url: getCFWebUrl() + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });

}

export function saveProfile(profile, userID) {
    console.log(JSON.stringify(profile));
    return request({
        url: getCFWebUrl() + "/user/saveProfile" + "?userID=" + userID,
        method: 'POST',
        body: JSON.stringify(profile)
    });
}


/**Waypoint calls */
export function getWaypoints(userID) {
    return request({
        url: getCFWebUrl() + "/waypoints/load" + "?userID=" + userID,
        method: 'POST',
        // body: JSON.stringify(tripRequst)
    });
}

/**Waypoint calls */
export function getWaypointsByFins(userID) {
    return request({
        url: getCFWebUrl() + "/waypoints/load/fins" + "?userID=" + userID,
        method: 'POST',
        // body: JSON.stringify(tripRequst)
    });
}

export function saveWaypoint(waypoint, userID) {
    console.log(JSON.stringify(waypoint));
    return request({
        url: getCFWebUrl() + "/waypoints/save" + "?userID=" + userID,
        method: 'POST',
        body: JSON.stringify(waypoint)
    });
}

export function getWaypointTypes() {
    return request({
        url: getCFWebUrl() + "/waypoints/getWaypointTypes",
        method: 'GET'
    });
}

export function deleteWaypoints(waypoint) {
    return request({
        url: getCFWebUrl() + "/waypoints/delete",
        method: 'POST',
        body: JSON.stringify(waypoint)
    });
}

/**Weather calls */
export function getWaypointsWeather() {
    return request({
        url: getCFWebUrl() + "/waypoints/load/weather",
        method: 'GET',
        // body: JSON.stringify(tripRequst)
    });
}

export function getDailyUserAverage3HrWeatherforVB(userID) {
    return weatherApiRequest({
        url: getCFWeatherUrl() + "/getDailyAveragesForUserEvery3Hrs?userId=" + userID,
        method: 'POST',
        // body: JSON.stringify(tripRequst)
    });
}

export function getWeatherAvgsPerDay(userID) {
    return weatherApiRequest({
        url: getCFWeatherUrl() + "/getAveragesForUserPerDay?userId=" + userID,
        method: 'POST',
        // body: JSON.stringify(tripRequst)
    });
}
export function getVBWeatherDetails(userId, waypointId, dateTime) {
    return weatherApiRequest({
        url: getCFWeatherUrl() + "/virtualbuoy/getWeatherForVB?userId=" + userId + "&waypointId=" + waypointId + '&dateTime=' + dateTime,
        method: 'POST',
        // body: JSON.stringify(waypoint)
    });
}

export function getWeatherForWaypoint(waypoint, userId) {
    return weatherApiRequest({
        url: getCFWeatherUrl() + "/getWeatherForWaypoint?latitude=" + waypoint.latitude + "&longitude=" + waypoint.longitude + '&id=' + waypoint.id + '&userId=' + userId,
        method: 'POST',
        // body: JSON.stringify(waypoint)
    });
}

export function runRefreshForAllUsers() {
    return weatherApiRequest({
        url: getCFWeatherUrl() + "/updateWeatherForUsers",
        method: 'POST',
        // body: JSON.stringify(waypoint)
    });
}
/**end of weather */

/***Begin Trip */
const uploadFileRequest = (options) => {
    const headers = new Headers()

    console.log(localStorage.getItem(ACCESS_TOKEN));
    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                // console(response);
                if (!response.ok) {
                    return Promise.reject(json);
                }
                console.log("Request " + options.url + " JSON:  " + JSON.stringify(json));
                return json;
            })
        );
};

export function getTrips(userID) {
    return request({
        url: getCFWebUrl() + "/trips/load" + "?userID=" + userID,
        method: 'GET'
    });
}

export function deleteTrip(tripId) {
    return request({
        url: getCFWebUrl() + "/trips/delete" + "?tripId=" + tripId,
        method: 'GET'
    });
}
export function uploadTrip(tripData) {
    return uploadFileRequest({
        url: getCFWebUrl() + "/trips/createTrip",
        method: 'POST',
        body: tripData
    });
}

export function uploadWaypoints(waypoints) {
    return uploadFileRequest({
        url: getCFWebUrl() + "/waypoints/upload",
        method: 'POST',
        body: waypoints
    });
}
/**End Trip */

/**payment begin x */
export function pay(payment) {
    return requestRedirect({
        url: getCFWebUrl() + "/payment/create-session",
        method: 'POST',
        redirect: 'follow',
        body: JSON.stringify(payment)
    });
}
/**payment end */

