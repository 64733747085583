import React, { Component } from 'react'
import L from 'leaflet';
import { Polyline, LayersControl } from 'react-leaflet'
import {floor, random} from 'mathjs'

const polyline = [
]
const myRouteTest = [
    [34.452013,-75.821677],
[34.457749,-75.823401],
[34.462844,-75.827613],
[34.467903,-75.831405],
[34.469101,-75.825433],
[34.472537,-75.826517],
[34.46738,-75.823303],
[34.466348,-75.821663],
[34.462159,-75.815665],
[34.457858,-75.809724],
[34.453227,-75.806552],
[34.450229,-75.803527],
[34.444998,-75.798925],
[34.439271,-75.796441],
[34.433141,-75.796452],
[34.482771,-75.718814],
[34.51903,-76.442413],
[34.519628,-76.465259],
[34.521005,-76.48865],
[34.525232,-76.508717]
]


export default class TripLine extends React.Component {
    constructor(props) {
        console.log('TripLine constructor');
        super(props);
        this.state = {
            trip: props.trip,
            latLong: [{ nalatitudeme: "", longitude: "" }],
            color: '',
            linePositions:[]
        };
    }

    componentDidMount() {   
        this.color = { color: this.getLineColor() };
        if (this.props.trip) {
            this.state.linePositions = this.loadTripPositions(this.props.trip);
        }
    }

    /**Load the trip postions into an array and then set it
     * on the PolyLine
     */
    loadTripPositions(trip) {
        let tempLatLong = [];
        trip.tripWaypoint.map(waypoint => 
            tempLatLong.push([waypoint.latitude, waypoint.longitude])
        );

        // if (tempLatLong && tempLatLong.length > 0) {
        //     const tmp = tempLatLong[0];
        //     this.props.setMapPosition(tmp);
        // }
        console.log(myRouteTest);
        console.log(tempLatLong);
        return tempLatLong;
    }

    /**Generate a random color for each trip path */
    getLineColor() {
        console.log("getting color");
        var r = floor(random() * 255);
        var g = floor(random() * 255);
        var b = floor(random() * 255)
        return "rgb("+r+" ,"+g+","+ b+")";
    }

    render() {
        return (

            <LayersControl.Overlay checked name={this.props.trip.name}>
                <Polyline pathOptions={this.color} positions={this.state.linePositions} />
            </LayersControl.Overlay>
        );
    }
}