import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import CloudQueueSharpIcon from '@mui/icons-material/CloudQueueSharp';
import DirectionsWalkSharpIcon from '@mui/icons-material/DirectionsWalkSharp';
import PlaceSharpIcon from '@mui/icons-material/PlaceSharp';
import FlagSharpIcon from '@mui/icons-material/FlagSharp';
import CloudCircleSharpIcon from '@mui/icons-material/CloudCircleSharp';
import FINsIcon from '@mui/icons-material/Timeline';
import DirectionsBoatSharpIcon from '@mui/icons-material/DirectionsBoatSharp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import WaypointDialog from './WaypointDialog';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 300,
    flexGrow: 1,
    maxWidth: 200,
    overflowY: 'scroll',
  },
});

export default function WaypointTree({ waypoints, updateParentState }, props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [waypoint, setWaypoint] = React.useState();
  const [childNodes, setChildNodes] = React.useState(null);
  const [expanded, setExpanded] = React.useState([]);

  /**This function will allow us to lazy load the tree as the 
   * parent is selected. 
   */
  const handleChange = (event, nodes) => {
    const expandingNodes = nodes.filter(x => !expanded.includes(x));
      setExpanded(nodes);
    if (expandingNodes[0]) {
      const childId = expandingNodes[0];
      if (nodes[0] == 51) {
        console.log('51');
        if (waypoints && waypoints.length) {
          setChildNodes(waypoints.map(waypoint =>
            <StyledTreeItem
              nodeId={waypoint.id}
              key={waypoint.id}
              labelText={waypoint.name}
              labelIcon={FlagSharpIcon}
              // labelInfo="2,294"
              color="#e3742f"
              bgColor="#fcefe3"
              onClick={() => handleOnClick(waypoint)}
              />
          ));
        } 
      }else if (nodes[0] == 50) {
        setChildNodes(waypoints.filter(waypoint => waypoint.virtualBuoy === true).map(waypoint =>
            <StyledTreeItem
              nodeId={waypoint.id}
              key={waypoint.id}
              labelText={waypoint.name}
              labelIcon={FlagSharpIcon}
              // labelInfo="2,294"
              color="#e3742f"
              bgColor="#fcefe3"
              onClick={() => handleOnClick(waypoint)}
              />
          ))        
      }
      // fetchChildNodes(childId).then(result =>
      //   setChildNodes(
      //     result.children.map(node => <MyTreeItem key={node.id} {...node} />)
      //   )
      // );
    }
  };

  const handleOnClick = (waypoint) => {
    const index = waypoints.indexOf(waypoint);
    setOpen(true);
    setWaypoint(waypoint);
  }

  const discard = useCallback(event => {
    setOpen(false);
  }, [])

  const handleUpdateState = useCallback(waypoint => {
    setOpen(false);
  }, [waypoints])

  return (
    <div>
      <TreeView
        className={classes.root}
        // defaultExpanded={['2']}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        onNodeToggle={handleChange}
      >
        
        <StyledTreeItem nodeId="50" labelText="FINs" labelIcon={FINsIcon}>
        {childNodes || [<div key="stub" />]}
          {/* {waypoints.filter(waypoint => waypoint.virtualBuoy === true).map((waypoint) => (
            <StyledTreeItem
              nodeId={waypoint.id}
              key={waypoint.id}
              labelText={waypoint.name}
              labelIcon={CloudCircleSharpIcon}
              // labelInfo="90"
              color="#1a73e8"
              bgColor="#e8f0fe"
              onClick={() => handleOnClick(waypoint)}
            />
          ))} */}
        </StyledTreeItem>
        <StyledTreeItem nodeId="51" labelText="Waypoints" labelIcon={PlaceSharpIcon}>
        {childNodes || [<div key="stub" />]}
          {/* {waypoints && waypoints.length > 0 ?
            waypoints.map((waypoint) => (
              // <TreeItem
              //   nodeId={waypoint.id}
              //   label={waypoint.name}
              //   onClick={() => handleOnClick(waypoint)}
              // />
              <StyledTreeItem
                nodeId={waypoint.id}
                key={waypoint.id}
                labelText={waypoint.name}
                labelIcon={FlagSharpIcon}
                // labelInfo="2,294"
                color="#e3742f"
                bgColor="#fcefe3"
                onClick={() => handleOnClick(waypoint)}
              />
            )) : <span></span>} */}

        </StyledTreeItem >
        {/* <StyledTreeItem nodeId="3" labelText="Routes" labelIcon={DirectionsWalkSharpIcon}>
          <StyledTreeItem
            nodeId="7"
            labelText="Route"
            labelIcon={DirectionsBoatSharpIcon}
            // labelInfo="3,566"
            color="#a250f5"
            bgColor="#f3e8fd"
          />
        </StyledTreeItem> */}
      </TreeView>

      <WaypointDialog doOpen={open} waypoint={waypoint} updateParentState={handleUpdateState} waypoints={waypoints} discard={discard}></WaypointDialog>
    </div>

  );
}
