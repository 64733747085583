import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import PlaceSharpIcon from '@mui/icons-material/PlaceSharp';
import FlagSharpIcon from '@mui/icons-material/FlagSharp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Button from '@mui/material/Button';
import { deleteTrip } from '../util/APIUtils';
import Alert from 'react-s-alert';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  Grid
} from '@mui/material';
import { useMap } from 'react-leaflet';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 300,
    flexGrow: 1,
    maxWidth: 200,
    overflowY: 'scroll',
  },
});



function TripTreeMenu(props) {
  const { open, onClose, trip, trips } = props;

  const handleClose = (isDeleted) => {
    onClose(isDeleted);
  };

  const removeTrip = () => {
    const name = trip.name;
    deleteTrip(trip.tripID)
      .then(response => {

        handleClose(true);
        Alert.success("Successfully deleted trip " + name);
      }).catch(error => {
        Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
      });
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Manage Trip {trip ? trip.name : ''}</DialogTitle>
        <Grid container>
          <Button color="primary" variant="contained" onClick={removeTrip}>Delete</Button>
          <Button color="primary" variant="contained" onClick={() => handleClose(false)}>Cancel</Button>
        </Grid>
      </Dialog>
    </div>
  )
}

export default function TripTree(props) {
  const {  trips, updateParentState, deleteTrip} = props;
  const classes = useStyles();
  const [currentTrip, setCurrentTrip] = React.useState();
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);


  const handleOnClick = (trip) => {
    setIsPopupOpen(isPopupOpen ? false : true);
    setCurrentTrip(trip);
  }

  const handleClose = (isDelete) => {
    if(isDelete) {
      deleteTrip(currentTrip);
    }
    setIsPopupOpen(false);
  };

  return (
    <div>
      <TreeView
        className={classes.root}
        // defaultExpanded={['2']}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        <StyledTreeItem nodeId="2" labelText="Trips" labelIcon={PlaceSharpIcon}>
          {trips && trips.length > 0 ?
            trips.map((trip) => (
              <StyledTreeItem
                nodeId={trip.tripID}
                key={trip.tripID}
                labelText={trip.name}
                labelIcon={FlagSharpIcon}
                // labelInfo="2,294"
                color="#e3742f"
                bgColor="#fcefe3"
                onClick={() => handleOnClick(trip)}
              />
            )) : <span></span>}

        </StyledTreeItem>

      </TreeView>

      <TripTreeMenu open={isPopupOpen} onClose={handleClose} trip={currentTrip} trips={trips}></TripTreeMenu>
      {/* <WaypointDialog doOpen={open} waypoint={waypoint} updateParentState={handleUpdateState} waypoints={waypoints}></WaypointDialog> */}
    </div>

  );
}
