import React, { useState, useEffect } from 'react';
import './CFStripeForm.css';
import { Typography, Grid } from '@mui/material';

const getPaymentUrl = (props) => {
    var loginResponse = props.loginResponse;
    if (loginResponse == null && props.location != null) {
        loginResponse = props.location.state.loginResponse;
    }
    return loginResponse.professionalPaymentUrl;
}

const ProductDisplay = (props) => (
    <section>
        <div className="product">
            <div className="description">
                <Grid container justifyContent="center" spacing={1}>
                    <Grid item>
                        <Typography variant="h4"><b>$15/month with 15 day Free Trial</b></Typography>
                    </Grid>

                </Grid>
            </div>
        </div>
        <Grid container justifyContent="center" direction="column" spacing={1} xl={12} >
            <Grid item lg={12}>
                <Typography variant="body2">Upload Trip data for future analysis and your very own digital log book</Typography>
            </Grid>
            <Grid item lg={12}>
                <Typography variant="body2">Create Fishing Intelligent Networks (FINs) (a.k.a waypoints)</Typography>
            </Grid>
            <Grid item lg={12}>
                <Typography variant="body2">Weather, Astronomy, and Tide data</Typography>
            </Grid>
            <Grid item lg={12}>
                <Typography variant="body2">User your own data for trip analysis</Typography>
            </Grid>
            <Grid item lg={12}>
                <Typography variant="body2"> Personlized Fishing Analysis</Typography>
            </Grid>
            <Grid item lg={12}>
                <Typography variant="body2">Upload and Manage your Waypoints </Typography>
            </Grid>
            <Grid item lg={12}>
                <Typography variant="body2">Cancel at anytime </Typography>
            </Grid>
            {/* <Grid item lg={12}>
                <Typography variant="body2"><b>Satellite Imaging</b></Typography>
            </Grid> */}

        </Grid>
        <Grid container justifyContent="center" spacing={2}>
            <Grid item>
                <form action={getPaymentUrl(props)} method="POST">
                    {/* Add a hidden field with the lookup_key of your Price.  This is set in the server application prop file */}
                    <input type="hidden" name="lookup_key" value="{{PRICE_LOOKUP_KEY}}" />
                    <button id="checkout-and-portal-button" type="submit" className='product-checkout-button'>
                        Checkout
                    </button>
                </form>
            </Grid>
        </Grid>
    </section>
);

const SuccessDisplay = ({ sessionId }) => {
    return (
        <section>
            <div className="product Box-root">
                <Logo />
                <div className="description Box-root">
                    <h3>Subscription to starter plan successful!</h3>
                </div>
            </div>
            <form action="/create-portal-session" method="POST">
                <input
                    type="hidden"
                    id="session-id"
                    name="session_id"
                    value={sessionId}
                />
                <button id="checkout-and-portal-button" type="submit">
                    Manage your billing information
                </button>
            </form>
        </section>
    );
};

const Message = ({ message }) => (
    <section>
        <p>{message}</p>
    </section>
);

export default function ProfessionalSubscription(props) {
    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');
    let [formSubmitUrl, setFormSubmitUrl] = useState('');

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id'));
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
        if (query.get('paymentUrl')) {
            setFormSubmitUrl(query.get('paymentUrl'));
        }
    }, [sessionId]);

    if (!success && message === '') {
        return <ProductDisplay {...props} />;
    } else if (success && sessionId !== '') {
        return <SuccessDisplay sessionId={sessionId} />;
    } else {
        return <Message message={message} />;
    }
}

const Logo = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="14px"
        height="16px"
        viewBox="0 0 14 16"
        version="1.1"
    >
        <defs />
        <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="0-Default"
                transform="translate(-121.000000, -40.000000)"
                fill="#E184DF"
            >
                <path
                    d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
                    id="Pilcrow"
                />
            </g>
        </g>
    </svg>
);