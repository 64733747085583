import React, { Component, useMemo, useState, useRef, useCallback, useEffect } from 'react'
import {
    TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox, FormControlLabel,
    FormControl, Select, MenuItem, Grid
} from '@mui/material';

import { getWeatherForWaypoint, getWaypointTypes, saveWaypoint, deleteWaypoints } from '../util/APIUtils';
import Alert from 'react-s-alert';

/**this is the dialog that is used for editing a waypoint either by clicking on the map or the waypoint in the tree.
 * It is also launched upon creation
 * 
 * @waypoint is the waypoint that the dialog will use to display data for and save
 * @doOpen true/false flag to display the dialog
 * @updateParentState is the call back to update the parent if needed
 * @waypoints is the full list of all waypoints which is passed back to the parent for updating
 */
export default function WaypointDialog({ waypoint, doOpen, updateParentState, waypoints, discard, props } ) {
    const [waypointTypes, setWaypointTypes] = React.useState();
    const [open, setOpen] = React.useState(doOpen);
    /**this is for the dialog to config the waypoint on setup */
    const [lastLat, setLastLat] = React.useState();
    const [lastLng, setLastLng] = React.useState();
    const [name, setWaypointName] = React.useState();
    const [isVirtualBuoy, setIsVirtualBuoy] = React.useState(false);
    const [waypointTypeId, setWaypointTypeId] = React.useState(6);//default to waypoint

    useEffect(() => {
        setOpen(doOpen);
        if (waypoint != null) {
            setLastLat(waypoint.latitude);
            setLastLng(waypoint.longitude);
            setWaypointName(waypoint.name);
            setIsVirtualBuoy(waypoint.virtualBuoy);
            if (waypoint.waypointTypeId != null) {
                setWaypointTypeId(waypoint.waypointTypeId.id);
            } else {
                waypoint.waypointTypeId = { id: 6 }
                setWaypointTypeId(6);
            }
        }
        //load waypoint types for select
        console.log('loading waypoints');
        getWaypointTypes()
            .then(response => {
                console.log("got weather data for waypoint=" + response);
                setWaypointTypes(response);
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }, [doOpen])

    const handleUpdateState = useCallback(event => {
        updateParentState(event)
    }, [waypoints])

    /**This function handle the save of the waypoint edit dialog */
    const handleSave = (event) => {
        //todo:  save data to backend
        event.preventDefault();
        waypoint.latitude = lastLat;
        waypoint.name = name;
        waypoint.longitude = lastLng;
        waypoint.virtualBuoy = isVirtualBuoy;
        waypoint.waypointTypeId.id = waypointTypeId;

        const profileRequest = {
            waypointId: waypoint.id, waypointName: name, latitude: lastLat, longitude: lastLng, isVirtualBuoy: isVirtualBuoy,
            waypointTypeId: waypointTypeId
        };
        console.log('Submitting Waypoint Data=' + profileRequest);
        // updateWaypointArray(waypoint);
        saveWaypoint(profileRequest)
            .then(response => {
                console.log('Waypoint Saved');
                handleUpdateState(response);
                Alert.success("Waypoint " + name + " saved successfully");
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        
        setOpen(false);
    };

    const handleDelete = (event) => {
        //todo:  save data to backend
        event.preventDefault();
        const profileRequest = {
            waypointId: waypoint.id
        };
        console.log('Submitting Waypoint Data=' + profileRequest);
        waypoint.isDeleted = true;
        // updateWaypointArray(waypoint);
        deleteWaypoints(profileRequest)
            .then(response => {
                console.log('Waypoint deleted');
                waypoint.isDeleted = true;
                response.isDeleted = true;
                handleUpdateState(waypoint);
                Alert.success("Waypoint " + waypoint.name + " deleted successfully");
            }).catch(error => {
                waypoint.isDeleted = false;
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    };

    const handleDiscard = event => {
        setOpen(false);
        if (discard)
            discard();
    }

    const handleTypeChange = (event) => {
        const key = event.target.name;
        const value = event.target.value;
        setWaypointTypeId(value);
    };

    const handleVirtualBuoyOnChange = (event) => {
        setIsVirtualBuoy(event.target.checked);
    }

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Waypoint Details</DialogTitle>
            <form onSubmit={handleSave}>
                <DialogContent>
                    <DialogContentText>
                        Add details about this waypoint
                    </DialogContentText>
                    <Grid>
                        <Grid container spacing={2}>
                            <Grid item>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Waypoint Name"
                                    type="text"
                                    fullWidth
                                    value={name}
                                    onChange={e => setWaypointName(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="latitude"
                                    label="Latitude"
                                    type="text"
                                    fullWidth
                                    value={lastLat}
                                    onChange={e => setLastLat(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="longitude"
                                    label="Longitude"
                                    type="text"
                                    fullWidth
                                    value={lastLng}
                                    onChange={e => setLastLng(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    value=""
                                    id="isVirutalBuoy"
                                    control={<Checkbox color="primary" onChange={handleVirtualBuoyOnChange} checked={isVirtualBuoy} />}
                                    label="Is this a FIN Waypoint?"
                                    labelPlacement="start"

                                />
                            </Grid>
                            <Grid item>
                                <FormControl variant="outlined" style={{ minWidth: 170 }}>
                                    <Select
                                        labelId="waypointType"
                                        id="waypointType"
                                        name="waypointType"
                                        value={waypointTypeId}
                                        onChange={handleTypeChange}
                                    >
                                        {waypointTypes && waypointTypes.map((item) =>
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="outlined"
                        onClick={(event) => {
                            handleDelete(event);
                        }}>
                        Delete
                    </Button>
                    <Button color="primary" variant="outlined"
                        onClick={() => {
                            handleDiscard();
                        }}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="outlined">
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}