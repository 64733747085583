// import React from 'react';
import React, { Component } from 'react';
import {
    Select, MenuItem, FormControl, InputLabel, FormLabel, Typography, Slider, Divider, Container, TextField, FormControlLabel, CssBaseline, Button, Avatar, Link, Grid, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { WATERTYPE_FRESHWATER, WATERTYPE_SALTWATER, WATERTYPE_INSHORE, WATERTYPE_OFFSHORE, COPYRIGHT } from '../../constants';
import MapSearcher from './MapSearcher';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/" underline="hover">
                {COPYRIGHT}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        maxWidth: 250
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class WeatherProfile extends Component {

    constructor(props) {
        console.log('Profile2 constructor');
        super(props);
        this.state = {
            maxWaveHeight: props.user.profile.maxWaveHeight,
            maxWindSpeed: props.user.profile.maxWindSpeed,
            bestWindDirection: props.user.profile.bestWindDirection,
            worseWindDirection: props.user.profile.worseWindDirection,
            speedUnitOfMeasure: props.user.profile.speedUnitOfMeasure,
            distanceUnitOfMeasure: props.user.profile.distanceMeasurementUnits,
            minWaterTemp: props.user.profile.minWaterTemp,
            maxWaterTemp: props.user.profile.maxWaterTemp
        };
        this.props.handleUpdateParentState('maxWaveHeight', props.user.profile.maxWaveHeight);
        this.props.handleUpdateParentState('maxWindSpeed', props.user.profile.maxWindSpeed);
        this.props.handleUpdateParentState('bestWindDirection', props.user.profile.bestWindDirection,);
        this.props.handleUpdateParentState('worseWindDirection', props.user.profile.worseWindDirection);
        this.props.handleUpdateParentState('speedUnitOfMeasure', props.user.profile.speedUnitOfMeasure);
        this.props.handleUpdateParentState('distanceUnitOfMeasure', props.user.profile.distanceMeasurementUnits);
        this.props.handleUpdateParentState('minWaterTemp', props.user.profile.minWaterTemp);
        this.props.handleUpdateParentState('maxWaterTemp', props.user.profile.maxWaterTemp);

    }

    render() {
        const handleChange = (event) => {
            const key = event.target.name;
            const value = event.target.value;
            this.setState({ [key]: value });
            {this.props.handleChange(event)}
        };

        const handleSliderChange = (event, newVal, key) => {
            this.setState({ [key]: newVal });
            {this.props.handleUpdateParentState(key, newVal)}
        };

        return (
            <Grid>
                <Grid container spacing={2}>
                    <Grid item >
                        <FormControl variant="outlined" style={{ minWidth: 170 }}>
                            <InputLabel id="speedUnitOfMeasure-label">Speed Units</InputLabel>
                            <Select
                                labelId="speedUnitOfMeasure"
                                id="speedUnitOfMeasure"
                                name="speedUnitOfMeasure"
                                value={this.state.speedUnitOfMeasure != '' ? this.state.speedUnitOfMeasure : ''}
                                onChange={handleChange}
                            >
                                <MenuItem key={'NM'} value={'NM'}>Nautical Mile</MenuItem>
                                <MenuItem key={'MPH'} value={'MPH'}>MPH</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item >
                        <FormControl variant="outlined" style={{ minWidth: 170 }}>
                            <InputLabel id="distanceUnitOfMeasure-label">Distance Unit</InputLabel>
                            <Select
                                labelId="distanceUnitOfMeasure"
                                id="distanceUnitOfMeasure"
                                name="distanceUnitOfMeasure"
                                value={this.state.distanceUnitOfMeasure != '' ? this.state.distanceUnitOfMeasure: ""}
                                onChange={handleChange}
                            >
                                <MenuItem value='FT'>Feet</MenuItem>
                                <MenuItem value='Metric'>Metric</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item >
                        <FormControl variant="outlined" style={{ minWidth: 170 }}>
                            <InputLabel id="maxWaveHeight-label">Max Wave Heigh</InputLabel>
                            <Select
                                labelId="maxWaveHeight"
                                id="maxWaveHeight"
                                name="maxWaveHeight"
                                value={this.state.maxWaveHeight != '' ? this.state.maxWaveHeight: ""}
                                onChange={handleChange}
                            >
                                <MenuItem value={1}>1 FT</MenuItem>
                                <MenuItem value={2}>2 FT</MenuItem>
                                <MenuItem value={3}>3 FT</MenuItem>
                                <MenuItem value={4}>4 FT</MenuItem>
                                <MenuItem value={5}>5 FT</MenuItem>
                                <MenuItem value={6}>6 FT</MenuItem>
                                <MenuItem value={7}>7 FT</MenuItem>
                                <MenuItem value={8}>8 FT</MenuItem>
                                <MenuItem value={9}>9 FT</MenuItem>
                                <MenuItem value={10}>10 FT</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <br></br><Divider /><br></br>
                {/* now set your ideal water team range*/}
                <FormLabel component="legend"><Typography variant="h6">Set your ideal water temparature range</Typography></FormLabel>
                <Grid>
                    <Grid container spacing={2}>
                        <Grid item >
                            <FormControl variant="outlined" style={{ minWidth: 170 }}>
                                <Typography id="discrete-slider" gutterBottom>
                                    Min Water Temp
                                </Typography>
                                <Slider
                                    id="minWaterTemp"
                                    name="minWaterTemp"
                                    defaultValue={this.state.minWaterTemp != '' ? this.state.minWaterTemp: 65}
                                    // getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={0.5}
                                    marks
                                    min={0}
                                    max={110}
                                    onChange={(e, newVal) => handleSliderChange(e, newVal, 'minWaterTemp')}
                                />
                            </FormControl>
                        </Grid><Grid item >
                            <FormControl variant="outlined" style={{ minWidth: 170 }}>
                                <Typography id="discrete-slider" gutterBottom>
                                    Max Water Temp
                                </Typography>
                                <Slider
                                    id="maxWaterTemp"
                                    name="maxWaterTemp"
                                    defaultValue={this.state.maxWaterTemp != '' ? this.state.maxWaterTemp: 75}
                                    // getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={0.5}
                                    marks
                                    min={0}
                                    max={110}
                                    onChange={(e, newVal) => handleSliderChange(e, newVal, 'maxWaterTemp')}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <br></br><Divider /><br></br>
                {/* now do the wind conditions that are favorable and not favorable */}
                <FormLabel component="legend"><Typography variant="h6">Set wind conditions</Typography></FormLabel>
                <Grid>
                    <Grid container spacing={2}>
                        <Grid item >
                            <FormControl variant="outlined" style={{ minWidth: 170 }}>
                                <InputLabel id="bestWindDirection-label">Best Direction</InputLabel>
                                <Select
                                    labelId="bestWindDirection"
                                    id="bestWindDirection"
                                    name="bestWindDirection"
                                    value={this.state.bestWindDirection != '' ? this.state.bestWindDirection: ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value='North'>North</MenuItem>
                                    <MenuItem value='South'>South</MenuItem>
                                    <MenuItem value='East'>East</MenuItem>
                                    <MenuItem value='West'>West</MenuItem>
                                    <MenuItem value='Northeast'>Northeast</MenuItem>
                                    <MenuItem value='Northwest'>Northwest</MenuItem>
                                    <MenuItem value='Southeast'>Southeast</MenuItem>
                                    <MenuItem value='Southwest'>Southwest</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item >
                            <FormControl variant="outlined" style={{ minWidth: 170 }}>
                                <InputLabel id="worseWindDirection-label">Worst Direction</InputLabel>
                                <Select
                                    labelId="worseWindDirection"
                                    id="worseWindDirection"
                                    name="worseWindDirection"
                                    value={this.state.worseWindDirection != '' ? this.state.worseWindDirection: ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value='North'>North</MenuItem>
                                    <MenuItem value='South'>South</MenuItem>
                                    <MenuItem value='East'>East</MenuItem>
                                    <MenuItem value='West'>West</MenuItem>
                                    <MenuItem value='Northeast'>Northeast</MenuItem>
                                    <MenuItem value='Northwest'>Northwest</MenuItem>
                                    <MenuItem value='Southeast'>Southeast</MenuItem>
                                    <MenuItem value='Southwest'>Southwest</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item >
                            <FormControl variant="outlined" style={{ minWidth: 170 }}>
                                <InputLabel id="maxWindSpeed-label">Max Wind Speed</InputLabel>
                                <Select
                                    labelId="maxWindSpeed"
                                    id="maxWindSpeed"
                                    name='maxWindSpeed'
                                    value={this.state.maxWindSpeed != '' ? this.state.maxWindSpeed: ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={5}>5 MPH</MenuItem>
                                    <MenuItem value={10}>10 MPH</MenuItem>
                                    <MenuItem value={15}>15 MPH</MenuItem>
                                    <MenuItem value={20}>20 MPH</MenuItem>
                                    <MenuItem value={25}>25 MPH</MenuItem>
                                    <MenuItem value={30}>30 MPH</MenuItem>
                                    <MenuItem value={40}>40 MPH</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(useStyles)(WeatherProfile)