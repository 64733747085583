import React, { useState, useEffect } from 'react'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { uploadTrip } from '../util/APIUtils';
import Alert from 'react-s-alert';

const useStyles = makeStyles({
    root: {
        height: 50,
        flexGrow: 1,
        maxWidth: 400,
    },
    // root: {
    //     '& > *': {
    //       margin: theme.spacing(1),
    //     },
    //   },
    input: {
        display: 'none',
    },
    box: {
        height: 60,
        display: "flex",
        // border: "1px solid black",
        padding: 4
    },
    topLeftBox: {
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    centerBox: {
        justifyContent: "center",
        alignItems: "center"
    },
    bottomRightBox: {
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    }
});

export default function AddTripDialog (props)  {
    const {  isOpen, updateParent, addTrip} = props;
    const [openDlg, setOpenDlg] = useState(isOpen);
    const [tripName, setTripName] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const classes = useStyles();

    useEffect(() => {
        setOpenDlg(isOpen);
    }, [isOpen]);

    const handleClose = (event) => {
        updateParent(false);
        setOpenDlg(false);
        setIsSelected(false);
        setSelectedFile();
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('tripName', tripName);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        if (!selectedFile || selectedFile == null) {
            Alert.error("Please select a GPX file to upload!");
            return;
        }
        uploadTrip(formData)
            .then(response => {
                console.log('Loaded trip data');
                console.log(response);
                addTrip(response);
                handleClose();
                Alert.success("Loaded trip data successfully");
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });

    }

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
        setOpenDlg(true);
    };

    return (
        <div>
            <Dialog open={openDlg} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Choose a GPX File to upload</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Upload a GPX file from your GPS device
                        </DialogContentText>
                        <Box className={`${classes.box}`}
                            m={1}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="tripName"
                                label="Trip Name"
                                type="text"
                                fullWidth
                                required
                                onChange={e => setTripName(e.target.value)}
                            />
                        </Box>
                        <br></br>
                        <Box className={`${classes.box}`}
                            m={1} component="div">
                            <TextField
                                id="startDate"
                                label="Start"
                                type="date"
                                defaultValue={startDate}
                                className={classes.textField}
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => setStartDate(e.target.value)}
                            />
                            <TextField
                                id="endDate"
                                label="End"
                                type="date"
                                defaultValue={endDate}
                                className={classes.textField}
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => setEndDate(e.target.value)}
                            />
                        </Box>
                        <Box className={`${classes.box}`}
                            m={1} >
                            <Box className={`${classes.box}`}>
                                {isSelected ? <p><b>Filename:</b> {selectedFile.name}</p> : 'Choose a file for upload'}
                            </Box>
                            <input
                                className={classes.input}
                                id="contained-button-file"
                                type="file"
                                accept=".gpx"
                                onChange={changeHandler}
                            />
                            <label htmlFor="contained-button-file">
                                <Button variant="contained" color="primary" component="span">
                                    Browse...
                                </Button>
                            </label>
                        </Box>

                    </DialogContent>
                    <DialogActions>

                        <Button color="primary" variant="outlined"
                            onClick={(event) => {
                                handleClose();
                            }}>
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" variant="outlined">
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
};