import { Button, Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import L from 'leaflet';
//have to do all of this because React leaflet for some reason do not include images and you will need to reset default icons image
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { getImage } from '../img/CFImages';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LayersControl, MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import Alert from 'react-s-alert';
import { deleteWaypoints, getWeatherForWaypoint, getWaypointsByFins } from '../util/APIUtils';
import WaypointDialog from './WaypointDialog';
import WaypointFileUploadDlg from './WaypointFileUploadDlg';
import WaypointTree from './WaypointTree';
import SpeedTree from './SpeedTree';
import "./vListStyle.css";

// import WaypointVirtualTree from './WaypointVirtualTree';
// import VirtualTreeGPT from './VirtualTreeGPT';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 50,
        flexGrow: 1,
        maxWidth: 400,
    },
    gridBase: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    waypointMap: {
        padding: theme.spacing(6),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    uploadButton: {
        padding: theme.spacing(2),
        textAlign: 'center',
        // color: theme.palette.text.secondary,
        "@media only screen and (max-width: 550px)": {display: 'none'},
    },
    mapsTree: {
        padding: theme.spacing(2),
        textAlign: 'center',
        // color: theme.palette.text.secondary,
        "@media only screen and (max-width: 550px)": {display: 'none'},
    },
}));

const center = ['34.18157', '-76.153560'];


function AddMarker({ waypoints, updateParentState }) {

    const [currentWaypoint, setCurrentWaypoint] = React.useState();
    const [open, setOpen] = React.useState(false);

    const handleUpdateState = useCallback(waypoint => {
        setOpen(false);
        updateParentState(waypoint)
    }, [waypoints])

    const discard = useCallback(event => {
        setOpen(false);

    }, [])

    const [markerPos, setMarkerPos] = useState(null)
    const map = useMapEvents({
        click(e) {
            console.log('add marker onclick')
            setMarkerPos(e.latlng)
            map.locate();
            var waypoint = new Object();
            waypoint.latitude = e.latlng.lat;
            waypoint.longitude = e.latlng.lng;
            setCurrentWaypoint(waypoint);
            setOpen(true)
            // handleInputChange(waypoint);

        },
        // locationfound(e) {
        //   console.log('location found:', e)
        //   // setMarkerPos(e.latlng)
        //   // map.flyTo(e.latlng, map.getZoom())
        // },
    });

    return markerPos == null ? null : (
        <div>
            {/* <Marker position={markerPos}>
                <Popup>Lat:{markerPos.lat}<br></br>
                    Long:{markerPos.lng}
                </Popup>
            </Marker> */}
            <WaypointDialog doOpen={open} waypoint={currentWaypoint} updateParentState={handleUpdateState} waypoints={waypoints} discard={discard}></WaypointDialog>
        </div>
    )
}

function DraggableMarker({ waypoint, waypoints, updateParentState }) {
    const initialValue = new Array();
    const [draggable, setDraggable] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [position, setPosition] = useState([waypoint.latitude, waypoint.longitude])
    const [markerIcon, setMarkerIcon] = useState(icon)
    const [markerIconSize, setMarkerIconSize] = useState()
    const markerRef = useRef(null)


    useEffect(() => {
        console.log('loading waypoints');
        setPosition([waypoint.latitude, waypoint.longitude]);
        var newIcon = getImage(waypoint.waypointTypeId.image);
        setMarkerIcon(newIcon.img);
        setMarkerIconSize(newIcon.size);
        console.log("Draggable Marker position:  " + { position });

    }, []);

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                }
            },
        }),
        [],
    )
    const toggleDraggable = useCallback(() => {
        setDraggable((d) => !d)
    }, [])

    const openDialog = useCallback(() => {
        setIsOpen((d) => !d)
    }, [])

    const handleUpdateState = useCallback(event => {
        setIsOpen(false);
        updateParentState(event)
    }, [waypoints])

    const style = {
        top: 'center',
        color: 'blue'
    }

    const discard = useCallback(event => {
        setIsOpen(false);

    }, [])

    const handleDelete = (event) => {
        //todo:  save data to backend
        event.preventDefault();
        // const profileRequest = Object.assign({}, waypoint);
        const profileRequest = {
            waypointId: waypoint.id, waypointName: waypoint.name, latitude: waypoint.latitude, longitude: waypoint.longitude, isVirtualBuoy: waypoint.isVirtualBuoy,
            waypointTypeId: waypoint.waypointTypeId.id
        };
        console.log('Submitting Waypoint Data=' + profileRequest);
        deleteWaypoints(profileRequest)
            .then(response => {
                console.log('Waypoint deleted');
                waypoint.isDeleted = true;
                response.isDeleted = true;
                handleUpdateState(response);
                Alert.success("Waypoint " + waypoint.name + " deleted successfully");
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    };

    const parkIcon = new L.Icon({
        iconUrl: markerIcon,
        iconSize: markerIconSize,
        popupAnchor: [0, -15],
        shadowUrl: iconShadow,
        shadowAnchor: [13, 28]
    });

    return (
        <Marker
            draggable={draggable}
            eventHandlers={eventHandlers}
            position={[waypoint.latitude, waypoint.longitude]}
            ref={markerRef}
            icon={parkIcon}
            riseOnHover={true}
            riseOffset={600}>
            <Popup minWidth={90}>
                {waypoint.name}
                <br></br>
                {waypoint.latitude}
                <br></br>
                {waypoint.longitude}
                <br></br>
                <Button type="submit" color="primary" variant="outlined" onClick={handleDelete}>
                    Delete
                </Button>
                <Button color="primary" variant="outlined" onClick={openDialog}>Edit</Button>
                <br></br>
                <span style={style} onClick={toggleDraggable}>
                    {draggable
                        ? 'Click here to make marker not draggable'
                        : 'Click here to make marker draggable'}
                </span>
                <WaypointDialog doOpen={isOpen} waypoint={waypoint} updateParentState={handleUpdateState} waypoints={waypoints} discard={discard}></WaypointDialog>

            </Popup>
        </Marker>
    )
}

export default function WaypointMap(props) {
    const initialValue = new Array();
    const classes = useStyles();

    const [position, setPosition] = useState(center)
    const [waypoints, setWaypoints] = useState(initialValue);
    const [waypointsTree, setWaypointsTree] = useState(initialValue);
    const [open, setOpen] = React.useState(false);
    const [isFileUploadDlgOpen, setIsFormUploadDlgOpen] = useState(false);
    const [lastWaypoint, setLastWaypoint] = React.useState();

    // const treeData = generateTreeData(); // Generate your tree data here

    const updateParentState = (waypoint) => {
        if (waypoint.isDeleted && waypoint.isDeleted) {
            console.log("waypoint has been deleted refresh");
            const tmpWaypoints = [...props.currentUser.waypoints];
            // const index = tmpWaypoints.indexOf(waypoint);
            //need to use this to find the specific index for race conditions when adding/deleting
            //waypoints fast
            const index = tmpWaypoints.findIndex(x => x.id === waypoint.id);
            if (index != -1) {
                tmpWaypoints.splice(index, 1);
                // props.currentUser.waypoints=tmpWaypoints;
                setWaypoints(tmpWaypoints);
                setPosition(center);
                setLastWaypoint(waypoint);
                props.currentUser.waypoints = tmpWaypoints;
            }
        } else {//assume save
            if (waypoints.length == 0) {
                waypoints.push(waypoint);
            }
            const tmpWaypoints = [...waypoints];
            console.log("waypoint add: " + tmpWaypoints.filter(e => e.id === waypoint.id).length);
            if (waypoint.virtualBuoy) {
                getWeatherForWaypoint(waypoint, props.currentUser.id)
                    .then(response2 => {
                        console.log('Weather retrieved for waypoint');
                        // handleUpdateState(response);
                        Alert.success("Waypoint " + waypoint.name + " weather updated successfully");
                    }).catch(error => {
                        Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
            }
            if (tmpWaypoints.filter(e => e.id === waypoint.id).length === 0) {
                tmpWaypoints.push(waypoint);
            }
            setWaypoints(tmpWaypoints);
            setPosition(center);
            setLastWaypoint(waypoint);
            // sort by name
            tmpWaypoints.sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
            props.currentUser.waypoints = tmpWaypoints;
        }
    }

    const handleOpenFileUpload = (event) => {
        setIsFormUploadDlgOpen(true);
    }

    const updateParentWaypoints = (updatedWaypoints) => {
        setWaypoints(updatedWaypoints);
        // props.user.waypoints=updatedWaypoints;
    }

    // Similar to componentDidMount and componentDidUpdate:
    //have to pass the empty array at the end to stop the infinite loop
    //get all the waypoints...no need to call server as they are on the user object
    useEffect(() => {
        console.log('loading waypoints');
        var user = props.currentUser;
        console.log("waypoints from user:  " + user);
        setWaypoints(user.waypoints);
        //load waypoint types for select
        console.log('loading waypoints');
        getWaypointsByFins(user.id)
            .then(response => {
                console.log("got weather data for waypoint=" + response);
                setWaypointsTree(response);
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        if (user.waypoints && user.waypoints.length > 0) {
            const tmpPos = [user.waypoints[0].latitude, user.waypoints[0].longitude];
            setPosition(tmpPos);
        }
    }, []);

    const style = {
        width: '60vw',
        height: '80vh',
        "@media only screen and (max-width: 550px)": {height: '100vh', width: '100vh'},
        'marginLeft': 'auto',
        'marginRight': 'auto',
        top: 'center',
        'z-index': 30,
        position: 'relative'
    }

    const treeStyle = {
        'margin-top': '50px',
        "@media only screen and (max-width: 550px)": {display: 'none'},
    }


    return (
        <div className={classes.gridBase} >
            <Grid direction='row' container spacing={1}>
                <Grid style={treeStyle} container direction='column' item sm={4}>

                    <Paper className={classes.uploadButton}>
                        <Button color="primary" variant="contained"
                            onClick={handleOpenFileUpload}>Upload Waypoints</Button>
                    </Paper>
                    {/* <div className={classes.mapsTree}> */}
                    <Paper className={classes.mapsTree}>
                        {/* <WaypointTree waypoints={waypoints} updateParentState={updateParentState} /> */}
                        <SpeedTree waypoints={waypointsTree} updateParentState={updateParentState} props={props} />
                        {/* <WaypointVirtualTree waypoints={waypoints} /> */}
                        {/* <VirtualTreeGPT data={waypoints}/> */}
                    </Paper>
                    {/* </div> */}
                </Grid>
                <Grid container item sm={6}>
                    <Paper className={classes.waypointMap}>
                        <MapContainer style={style} center={position} zoom={9} crs={L.CRS.EPSG900913}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <LayersControl position="topright">
                                {/* <LayersControl.BaseLayer name="World Map">
                                    <TileLayer
                                        // attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                    />
                                </LayersControl.BaseLayer> */}
                                {/* <LayersControl.BaseLayer checked name="Ocean">
                                    <TileLayer
                                        // attribution='Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri'
                                        url="https://services.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}"
                                    />
                                </LayersControl.BaseLayer> */}
                                <LayersControl.BaseLayer checked name="Ocean">
                                    <TileLayer
                                        attribution="Google Maps Satellite"
                                        url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                                    />
                                </LayersControl.BaseLayer>
                            </LayersControl>

                            {waypoints.map((waypoint) => (
                                <DraggableMarker waypoint={waypoint} waypoints={waypoints} updateParentState={updateParentState} />
                            ))}

                            <AddMarker waypoints={waypoints} updateParentState={updateParentState}></AddMarker>


                            <WaypointFileUploadDlg isOpen={isFileUploadDlgOpen} updateParent={handleOpenFileUpload} updateParentWaypoints={updateParentWaypoints}></WaypointFileUploadDlg>
                        </MapContainer>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}