import React, { useState, useRef, useMemo, useCallback  } from 'react';
import { Marker, Popup } from 'react-leaflet'

const center = {
  lat: 51.505,
  lng: -0.09,
}

function DraggableMarker(parms) {
  const [draggable, setDraggable] = useState(false)
  const [position, setPosition] = useState(center)
  setPosition(parms.position);
  // const parms = this.parms;
  const markerRef = useRef(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
        }
      },
    }),
    [],
  )
  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d)
  }, [])

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}>
      <Popup minWidth={90}>
        <span onClick={toggleDraggable}>
          {draggable
            ? 'Marker is draggable'
            : 'Click here to make marker draggable'}
        </span>
      </Popup>
    </Marker>
  )
}

export default DraggableMarker;