import { useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import "./NavBar.css";
import chasinfins from '../img/Chasinfins-logo-black-on-white.jpg'

export default function NavBar( props ) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const toggleNav = () => {
    setIsNavExpanded(false);
  }

  return (
    <nav className="navigation">
      <a href="/" className="logo">
        <img classname="logo" src={chasinfins} />
      </a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
          console.log(isNavExpanded);
        }}
      >
        {/* icon from Heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
                                { props.authenticated ? (
                                    <ul>
                                        <li>
                                            <NavLink to="/dashboard" onClick={toggleNav}>Dashboard</NavLink>
                                        </li>
                                        {/* <li>
                                            <NavLink to="/satelite" onClick={toggleNav}>Satelite</NavLink>
                                        </li> */}
                                        <li>
                                            <NavLink to="/waypointMap" onClick={toggleNav}>Waypoints</NavLink>
                                        </li> 
                                        <li>
                                            <NavLink to="/trips" onClick={toggleNav}>My Trips</NavLink>
                                        </li> 
                                        <li>
                                            <NavLink to="/profile2" onClick={toggleNav}>Profile</NavLink>
                                        </li>                                        
                                        <li>
                                            <a onClick={props.onLogout}>Logout</a>
                                        </li>
                                    </ul>
                                ): (
                                    <ul>
                                        <li>
                                            <NavLink to="/login" onClick={toggleNav}>Login</NavLink>        
                                        </li>
                                        <li>
                                            <NavLink to="/signup" onClick={toggleNav}>Signup</NavLink>        
                                        </li>        
                                        <li>
                                            <NavLink to="/privacy" onClick={toggleNav}>Privacy</NavLink>        
                                        </li>                                
                                    </ul>
                                )}

      </div>
    </nav>
  );
}
