import { Box, Link, Typography} from '@mui/material';
import chasinfins300 from '../img/Chasinfins-logo-black-on-white300x300-2.png'

export default function Privacy() {
    return (
        <Box sx={{ width: '100%', maxWidth: 500, marginTop: 8, marginLeft: 20 }}>
            <Typography variant='h6'>
                Privacy Policy
            </Typography>
            <Typography variant='body1'>
                ChasinFins is the sole owner of the data and/Information collected on this site. We only have access to/collect information that you voluntarily
                give us via email or other direct contact from you. We will not sell or rent this information to anyone. We only use this information to provide a better user experience for you.
                We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, e.g. to ship an order. Unless you ask us not to,
                we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.
            </Typography>
            <Typography variant='body1' marginTop={2}>
                We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline. Wherever we collect sensitive information (such as credit card data),
                that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a closed lock icon at the beginning of the URL or looking for "https" at the beginning of the address of the web page.
                While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service)
                are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.
            </Typography>
            <Typography variant='body1' marginTop={2}>
                To use this website, a user must first complete the registration form. During registration a user is required to give certain information (such as name and email address). 
                This information is used to contact you about the products/services on our site in which you have expressed interest. At your option, you may also provide demographic information
                 (such as gender or age) about yourself, but it is not required.
                </Typography>
                <Typography variant='body1' marginTop={2}>
                We request information from you on our order form. To buy from us, you must provide contact information (like name and shipping address) and financial information
                 (like credit card number, expiration date). This information is used for billing purposes and to fill your orders. If we have trouble processing an order, we'll use this information to contact you.
                </Typography>
                <Typography variant='body1' marginTop={2}>
                We use "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard drive to help us improve your access to our site and identify repeat
                 visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. 
                 Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. The usage of a cookie is in no way linked to any personally identifiable information on our site.
                </Typography>
                <Typography variant='body1' marginTop={2}>
                This web site contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. 
                We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.
                </Typography>
                <Typography variant='body1' marginTop={2}>
                From time to time our site requests information via surveys or contests. Participation in these surveys or contests is completely voluntary and you may choose whether or not to participate and therefore disclose this information. Information requested may include contact information (such as name and shipping address), and demographic information (such as zip code, age level). Survey information will be used for the purpose of monitoring or improving the use and satisfaction of this site.
            </Typography>
                <Link>
                        <img src={chasinfins300}/>
                        </Link>   
        </Box>
        
    )
}