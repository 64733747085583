import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import { Grid, Typography } from '@mui/material';
import WeatherDayCard from './WeatherDayCard';
import makeStyles from '@mui/styles/makeStyles';
import Chart from '../Chart';
import { getDailyUserAverage3HrWeatherforVB, getWeatherAvgsPerDay, runRefreshForAllUsers } from '../../util/APIUtils';
import Alert from 'react-s-alert';
import WeatherDetailDayCard from './WeatherDetailDayCard';
import { CircularProgress } from '@mui/material';
import { blue } from '@mui/material/colors';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 140,
        width: 100,
    },
}));

function AddWeatherDeailCard({ day }) {
    const [expandedId, setExpandedId] = useState(-1);
}


export default function WeatherWidget({ props }) {
    const initialValue = new Array();
    const [waypoints, setWaypoints] = useState(initialValue);
    //this is averages for everyday at every 3HR intreval.
    const [weatherAvgs3Hr, setweatherAvgs3Hr] = useState(initialValue);
    const [weatherAvgsByDay, setweatherAvgsByDay] = useState(initialValue);
    const classes = useStyles();
    const [expandedId, setExpandedId] = React.useState(-1);
    const [progress, setProgress] = React.useState(0);
    const [showingDetails, setShowingDetails] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);;

    const [data, setData] = useState([]);
    const datas = [
        [10, 30, 40, 20],
        [10, 40, 30, 20, 50, 10],
        [60, 30, 40, 20, 30]
    ]
    var i = 0;

    useEffect(() => {
        changeData();
    }, []);

    const changeData = () => {
        setData(datas[i++]);
        if (i === datas.length) i = 0;
    }
    const handleExpandClick = i => {
        if (showingDetails == false) {
            setExpandedId(expandedId === i ? -1 : i);
        }
        console.log("weather card clicked");
    };

    const handleShowingDetails = useCallback(event => {
        setShowingDetails(event);
    }, [])

    const handleRefreshWeather = (event) => {
        console.log('running weather refresh for all users');
        runRefreshForAllUsers()
            .then(response => {
                console.log(response);
                // setweatherAvgs3Hr(response);
                // clearInterval(timer);
                console.log("run weather refresh for all user successful - " + response);
            }).catch(error => {
                console.log("Error running weather refresh=" + error);
                Alert.error('Error running weather refresh.');
            });
        console.log('done running weather refresh for all users');
    }
    // Similar to componentDidMount and componentDidUpdate:
    //have to pass the empty array at the end to stop the infinite loop
    //get all the waypoints
    useEffect(() => {
        // const timer = setInterval(tick, 20); 

        console.log('loading weather');
        var user = props.currentUser;
        setIsLoading(true);
        setWaypoints(user.waypoints);
        getDailyUserAverage3HrWeatherforVB(user.id)
            .then(response => {
                console.log(response);
                setweatherAvgs3Hr(response);
                console.log("response length=" + response.length);
                if (response.length == 0) {
                    console.log ("no weather data found");
                    Alert.error('Unable to load weather data. A possible cause of this is to ensure you have created waypoints in the Waypoint tab.',
                    {
                        timeout: 10000
                    });
                }
                setIsLoading(false);
                // clearInterval(timer);
                console.log("got weather averages" + weatherAvgs3Hr);
            }).catch(error => {
                console.log("Error getting weatehr=" + error);
                setIsLoading(false);
                Alert.error('Unable to load weather data. A possible cause of this is to ensure you have created waypoints in the Waypoint tab.',
                    {
                        timeout: 10000
                    });
            });

        getWeatherAvgsPerDay(user.id)
            .then(response => {
                console.log(response);
                setweatherAvgsByDay(response);
                setIsLoading(false);
                console.log("got weather averages by day" + weatherAvgsByDay);
            }).catch(error => {
                setIsLoading(false);
                // Alert.error('Unable to load weather averages by day. A possible cause of this is to ensure you have created FINs in the Waypoint tab.');
            });

        // function tick() {
        //     // reset when reaching 100%
        //     setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
        //   }


        //   return () => {
        //     clearInterval(timer);
        //   };            
    }, []);

    return (
        <Grid container className={classes.root} spacing={1}>

            {/* <Grid container spacing={1} alignItems="center" justify="center">
                <CircularProgress variant="determinate" value={progress} />
            </Grid> */}
            {/* <Grid>
                                        <Button variant="contained"
                                onClick={(event) => {
                                    handleRefreshWeather(event);
                                }}
                            >Refresh</Button>
            </Grid> */}
            {isLoading == true ?
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid>
                        <CircularProgress
                            size={68}
                            sx={{
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                marginTop: `40px`,
                                marginLeft: `50px`
                            }}
                        />
                    </Grid>
                    <Grid>
                        <Typography
                            sx={{
                                color: blue,
                                position: 'absolute',
                                top: '47%',
                                left: '47%',
                                marginTop: `40px`,
                                marginLeft: `50px`
                            }}
                        >Loading Weather Data</Typography>
                    </Grid>
                </Grid>

                :
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Chart weatherAvgsEvery3Hr={weatherAvgs3Hr} weatherAvgsByDay={weatherAvgsByDay} />
                    </Grid>
                </Grid>

            }
            <Grid className={classes.root} item xs={12}>
                <Grid container className={classes.root} justifyContent="center" spacing={1}>
                    {weatherAvgs3Hr.map((day, i) => (
                        //setting breakpoints to autosize by not giving a value
                        <Grid item xs sm md lg xl onClick={(event) => handleExpandClick(i)}>
                            {expandedId === i ? <WeatherDetailDayCard day={day} key={i} handleShowingDetails={handleShowingDetails} props={props} ></WeatherDetailDayCard> : <WeatherDayCard day={day} key={i}></WeatherDayCard>}
                            {/* <WeatherDayCard day={day} index={i} onClick={(event) => { setExpandedId(i) }}/> */}
                        </Grid>
                    ))}

                </Grid>
            </Grid>


        </Grid>
    );
}

WeatherWidget.defaultProps = {
    config: PropTypes.arrayOf({
        unit: 'metric',
    }),
    forecast: [],
};

WeatherWidget.propTypes = {
    forecast: PropTypes.arrayOf(
        PropTypes.shape({
            dt: PropTypes.number.isRequired,
            temp: PropTypes.number.isRequired,
            temp_min: PropTypes.number.isRequired,
            temp_max: PropTypes.number.isRequired,
            humidity: PropTypes.number.isRequired,
            icon: PropTypes.string.isRequired,
            desc: PropTypes.string.isRequired,
            clouds: PropTypes.number.isRequired,
            wind: PropTypes.number.isRequired,
        }),
    ),
    config: PropTypes.shape({
        location: PropTypes.string.isRequired,
        unit: PropTypes.string,
        locale: PropTypes.string,
    }),
};

const ContentContainer = styled.div`
  display: block;
  margin: 10px 5px;
  text-align: left;
  border: 1px solid #dddddd;
  box-shadow: 3px 3px 3px #aaaaaa;
  padding: 1rem 1rem;
`;

const Next5Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  justify-content: space-around;
`;

