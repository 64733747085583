import React, { Component } from 'react'
import L from 'leaflet';
import { FeatureGroup, WMSTileLayer } from 'react-leaflet'


export default class ERDDAPMap extends Component {

    render() {
        console.log(this.props.product);
        return (
            <FeatureGroup>
                <WMSTileLayer
                    attribution='NOAA NESDIS CoastWatch East Coast Node'
                    url={this.props.url}
                    bgcolor='0x808080'
                    crs={L.CRS.EPSG4326}
                    format='image/png'
                    layers={this.props.product}
                    styles=''
                    // time='2021-02-22T00:00:00Z'
                    transparent={true}
                    version='1.3.0'
                    colorBarPalette='BlackWhite'
                />
                <WMSTileLayer
                    attribution='NASA/GSFC OBPG'
                    url={this.props.url}
                    bgcolor='0x808080'
                    crs={L.CRS.EPSG4326}
                    format='image/png'
                    layers='Land'
                    styles=''
                    // time='2021-02-22T00:00:00Z'
                    transparent={true}
                    version='1.3.0'
                />                
                <WMSTileLayer
                    attribution='NASA/GSFC OBPG'
                    url={this.props.url}
                    bgcolor='0x808080'
                    crs={L.CRS.EPSG4326}
                    format='image/png'
                    layers='Coastlines'
                    styles=''
                    // time='2021-02-22T00:00:00Z'
                    transparent={true}
                    version='1.3.0'
                />
                <WMSTileLayer
                    attribution='NASA/GSFC OBPG'
                    url={this.props.url}
                    bgcolor='0x808080'
                    crs={L.CRS.EPSG4326}
                    format='image/png'
                    layers='LakesAndRivers'
                    styles=''
                    // time='2021-02-22T00:00:00Z'
                    transparent={true}
                    version='1.3.0'
                />
                <WMSTileLayer
                    attribution='NASA/GSFC OBPG'
                    url={this.props.url}
                    bgcolor='0x808080'
                    crs={L.CRS.EPSG4326}
                    format='image/png'
                    layers='Nations'
                    styles=''
                    // time='2021-02-22T00:00:00Z'
                    transparent={true}
                    version='1.3.0'
                />
                <WMSTileLayer
                    attribution='NASA/GSFC OBPG'
                    url={this.props.url}
                    bgcolor='0x808080'
                    crs={L.CRS.EPSG4326}
                    format='image/png'
                    layers='States'
                    styles=''
                    // time='2021-02-22T00:00:00Z'
                    transparent={true}
                    version='1.3.0'
                />
            </FeatureGroup>
        )
    };
}