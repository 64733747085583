import React, { Component } from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
//use this in order for the history.push to work
import { withRouter } from 'react-router-dom';
import AppHeader from '../common/AppHeader';
import NavBar from '../common/NavBar';
import Home from '../home/Home';
import Login from '../user/login/Login';
import SatApp from '../trip/SatApp'
import TripMap from '../trip/TripMap'
import WaypointMap from '../map/WaypointMap'
import Signup from '../user/signup/Signup';
import Profile from '../user/profile/Profile';
import Profile2 from '../user/profile/Profile2';
import Dashboard from '../dashboard/Dashboard';
import OAuth2RedirectHandler from '../user/oauth2/OAuth2RedirectHandler';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import { getCurrentUser } from '../util/APIUtils';
import { ACCESS_TOKEN } from '../constants';
import PrivateRoute from '../common/PrivateRoute';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import './App.css';
import { IdleTimer } from './IdleTimerComponent '
import Subscription from '../purchase/Subscription';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import registerServiceWorker from '../registerServiceWorker';

import makeStyles from '@mui/styles/makeStyles';
import { ConstructionOutlined } from '@mui/icons-material';
import Privacy from '../common/privacy';

const theme = createTheme();

const useStyles = makeStyles((theme) => {
  root: {
    // some CSS that accesses the theme
  }
});

class App extends Component {


  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      currentUser: null,
      loginResponse: null,
      loading: false,
      loggingout: false,
      //1000*60= one minute * 15 = 15 minute timeout
      timeout: 1000 * 60 * 15,
      isTimedOut: false,
      paymentUrl: ''
    }

    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }
  /**The following is to capture events and manger whether the user is idel and log them out if they are 
   * idle for more than 15 minutes
   */
  _onAction(e) {
    this.setState({ isTimedOut: false })
  }

  _onActive(e) {
    this.setState({ isTimedOut: false })
  }

  _onIdle(e) {
    console.log('user is idle', e)
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      this.handleLogout();
    } else {
      this.setState({ showModal: true })
      this.idleTimer.reset();
      this.setState({ isTimedOut: true })
    }

  }
  //******************End of idol security check */
  loadCurrentlyLoggedInUser() {
    this.setState({
      loading: true
    });

    getCurrentUser()
      .then(response => {
        this.setState({
          currentUser: response.user,
          authenticated: true,
          loading: false,
          paymentUrl: response.paymentUrl,
          loginResponse: response
        });
        console.log("PaymentUrl=" + response.standardPaymentUrl);
        console.log("Active Profile=" + response.activeProfile);
        console.log("Response=" + JSON.stringify(response));
        //is user has not setup profile send them to do that
        if (response.user.subscription.paid == false) {//if the user has not paid then move to purchase
          this.props.history.push('/purchase');
          this.setState({authenticated: false});
        } else if (response && response.user.profile.profileSetup == false) {
          this.props.history.push('/profile2');
        } else  if (response && response.user.waypoints && response.user.waypoints.length > 0) {
          this.props.history.push('/dashboard');
        }
      }).catch(error => {
        this.setState({
          loading: false,
          authenticated: false,
        });
      });



  }

  handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN);
    this.setState({
      authenticated: false,
      currentUser: null,
      loggingout: true
    });
    Alert.success("You're safely logged out!");
  }

  componentDidMount() {
    // this.loadCurrentlyLoggedInUser();
  }

  // componentDidUpdate() {
  //   console.log("App did update");
  // }

  // componentWillUpdate() {
  //   console.log("app will update");
  // }

  render() {
    if (this.state.loggingout) {
      this.setState({ loggingout: false });
      return <Redirect to="/" push={true} />
    }

    return (

<ThemeProvider theme={theme}>
      <div className="app">
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction} F
          debounce={250}
          timeout={this.state.timeout} />

        <div className="app-top-box">
          <NavBar authenticated={this.state.authenticated} onLogout={this.handleLogout} />
        </div>
        <div className="app-body">
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <PrivateRoute path="/profile" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              component={Profile}></PrivateRoute>
            <PrivateRoute path="/profile2" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              component={Profile2}></PrivateRoute>
            <PrivateRoute path="/waypointMap" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              component={WaypointMap}></PrivateRoute>
            <PrivateRoute path="/dashboard" authenticated={this.state.authenticated} currentUser={this.state.currentUser} loading={this.state.loading}
              component={Dashboard}></PrivateRoute>
            <PrivateRoute path="/trips" authenticated={this.state.authenticated} currentUser={this.state.currentUser} loading={this.state.loading}
              component={TripMap}></PrivateRoute>
            <Route path="/satelite"
              render={(props) => <SatApp authenticated={this.state.authenticated} currentUser={this.state.currentUser} {...props} />}></Route>
            <Route path="/login"
              render={(props) => <Login authenticated={this.state.authenticated} onSuccessLogin={this.loadCurrentlyLoggedInUser} {...props} />}></Route>
                          <Route path="/privacy"
              render={(props) => <Privacy authenticated={this.state.authenticated} onSuccessLogin={this.loadCurrentlyLoggedInUser} {...props} />}></Route>
            <Route path="/signup"
              render={(props) => <Signup authenticated={this.state.authenticated} {...props} />}></Route>
            <Route path="/purchase"
              render={(props) => <Subscription authenticated={this.state.authenticated} loginResponse={this.state.loginResponse}  {...props} />}></Route>
            <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}></Route>
            <Route component={NotFound}></Route>
          </Switch>
        </div>
        <Alert stack={{ limit: 3 }}
          timeout={3000}
          position='top-right' effect='slide' offset={65} />
          
      </div>
      </ThemeProvider>
    );
  }
}

export default withRouter(App);
