import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles({
  root: {
      '& .super-app-theme--header': {
          backgroundColor: 'rgba(0, 118, 216, 0.53)',
      },
      '& .column-width': {
          width: 150,
      },
  },
});

const columns = [

  {
      field: 'dateTime',
      headerName: 'Date & Time',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      width: 175,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: (params) => {
          const date = moment(params.value).utc().format('MM/DD/YYYY');
          const time = moment(params.value).utc().format('LT');
          return `${date} : ${time} `;
      }
  },
  {
      field: 'airTemp',
      headerName: 'Air Temp',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      width: 125,
      editable: false,
      align: 'center',
      headerAlign: 'center',
  },
  {
      field: 'waveHeight',
      headerName: 'WH',
      headerClassName: 'super-app-theme--header',
      description: 'Significant Wave Height.  This is the average of the highest one-third (33%) of waves (measured from trough to crest) that occur in a given period',
      type: 'number',
      width: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center'
  },
  {
      field: 'wavePeriod',
      headerName: 'WP',
      headerClassName: 'super-app-theme--header',
      description: 'Significant Wave Period',
      type: 'number',
      width: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center'
  },
  {
      field: 'waveDirection',
      headerName: 'WD',
      headerClassName: 'super-app-theme--header',
      description: 'Significant Wave Direction',
      type: 'number',
      width: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center'
  },
  {
      field: 'waterTemp',
      headerName: 'SST',
      description: 'Sea Surface Temparature',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      width: 115,
      editable: false,
      align: 'center',
      headerAlign: 'center'
  },
  {
      field: 'windSpeed',
      headerName: 'WS',
      description: 'Wind Speed',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      width: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center'
  },
  {
      field: 'windDirection',
      headerName: 'WD',
      description: 'Wind Direction',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      width: 175,
      editable: false,
      align: 'center',
      headerAlign: 'center'
  },
  {
      field: 'currentSpeed',
      headerName: 'CS',
      description: 'Current Speed',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      width: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center'
  },
  {
      field: 'currentDirection',
      headerName: 'CD',
      description: 'Current Direction',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      width: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center'
  },
  {
      field: 'swellHeight',
      headerName: 'SH',
      description: 'Swell Height',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      width: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center'
  },
  {
      field: 'swellPeriod',
      headerName: 'SP',
      description: 'Swell Period',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      width: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center'
  },
  {
      field: 'swellDirction',
      headerName: 'SD',
      description: 'Swell Direction',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      width: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center'
  },
];


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div style={{ height: 490, width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DetailWeatherTabs({data}) {
  const [value, setValue] = React.useState(0);
  const [weatherDetails, setWeatherDetails] = React.useState(data);

  useEffect(() => {
    // let tmpWaypointId = waypointId;
    console.log('In Detail WEather Tab');

}, [weatherDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <Box xl={{ width: '100%', height: 490}}>
      <Box xl={{ borderBottom: 1, borderColor: 'divider', height: 300 }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Item One" {...a11yProps(0)} />
          <Tab label="Item Two" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
                    <DataGrid
                        rows={weatherDetails}
                        columns={columns}
                        rowHeight={25}
                        pageSize={15}
                    />
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
    </Box>
  );
}