import React, { Component } from 'react'
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, LayersControl, ImageOverlay, WMSTileLayer } from 'react-leaflet'
import DraggableMarker from './DraggableMarker';


//have to do all of this because React leaflet for some reason do not include images and you will need to reset default icons image
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { ContactSupportOutlined } from '@mui/icons-material';
// import TripLine from './TripLine';
import ERDDAPMap from './ERDDAPMap';
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

// type State = {
//     lat: number,
//     lng: number,
//     zoom: number,
// }

class Location extends React.Component {
    constructor(props) {
        console.log('Location constructor');
        super(props);
        this.state = { location: props.location };
    }

    render() {
        const position = [this.props.location.latitude, this.props.location.longitude]

        return (
            <Marker draggable={true} position={position} icon={DefaultIcon}
                eventHandlers={{
                    dragend: (e) => {
                        var tmpMarker = e.target;
                        var newLoc = tmpMarker.getLatLng();
                        console.log('marker moved to = ' + newLoc, e)
                        console.log(this.state);
                    }
                }}
            >
                { <Popup>
                    Lat: {this.props.location.latitude}<br></br>
                    Long: {this.props.location.longitude}
                </Popup>}
            </Marker>
        )
    }
}
export default class GCMap extends Component {
    state = {
        lat: 34.18157,
        lng: -76.153560,
        zoom: 8,
    }

    render() {
        const position = [this.state.lat, this.state.lng]
        const style = {
            width: '50vw',
            height: '75vh',
            'marginLeft': 'auto',
            'marginRight': 'auto',
            top: 'center',
            'margin-top': '100px'
        }

        /**note it is important to make sure these align with the box of the request to the satellite data.  If
         * not the maps will not overlay correctly
         */
        const imageBounds = [
            [29.6125, -83.5],
            [37.025, -63.975]
            // [-89.975,-179.975],
            // [89.975, 179.975]
        ];
        /**
         * OpenStreetMap base/default map
            * attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            * url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
         * OpenStreetMap topo map
            * attribution= 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
            * url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
         * OpenStreetMap topo mapEsri.WorldImagery
                attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"

                TODO clean up all this ************************************************************
                                    <LayersControl.Overlay name="Surface Temperature">
                        <ImageOverlay
                            //	Chlorophyll-a, Aqua MODIS, NPP, East US, 2002-2012 (1 Day Composite)
                            url='https://coastwatch.pfeg.noaa.gov/erddap/griddap/erdMEchla1day.transparentPng?chlorophyll%5B(2012-14-03T12:00:00Z)%5D%5B(0.0)%5D%5B(29.6125):(37.025)%5D%5B(-83.5):(-63.975)%5D&.draw=surface&.vars=longitude%7Clatitude%7Cchlorophyll&.colorBar=%7C%7C%7C%7C%7C&.bgColor=0xffccccff'
                            /*GOES-16 ABI L3C SST (ABI G16-STAR-L3C-v2.70), GOES EAST Position, 0.02°,Hourly, 2017-present
                            *https://coastwatch.pfeg.noaa.gov/erddap/griddap/jplStarG16SSTv270.transparentPng?sea_surface_temperature%5B(2021-03-13T10:00:00Z)%5D%5B(40.99):(25.99)%5D%5B(-87.99001):(-55.99)%5D&.draw=surface&.vars=longitude%7Clatitude%7Csea_surface_temperature&.colorBar=%7C%7C%7C%7C%7C&.bgColor=0xffccccff
                            */
                            //    url='https://coastwatch.pfeg.noaa.gov/erddap/griddap/jplStarG16SSTv270.transparentPng?sea_surface_temperature%5B(2021-03-13T10:00:00Z)%5D%5B(40.99):(25.99)%5D%5B(-87.99001):(-55.99)%5D&.draw=surface&.vars=longitude%7Clatitude%7Csea_surface_temperature&.colorBar=%7C%7C%7C%7C%7C&.bgColor=0xffccccff'
                            // url='https://coastwatch.pfeg.noaa.gov/erddap/griddap/nesdisGeoPolarSSTN5NRT.transparentPng?analysed_sst%5B(2021-03-09T12:00:00Z)%5D%5B(25.025):(37.025)%5D%5B(-83.975):(-63.975)%5D&.draw=surface&.vars=longitude%7Clatitude%7Canalysed_sst&.colorBar=%7C%7C%7C%7C%7C&.bgColor=0xffccccff'
                          //  bounds={imageBounds}
                      //  />
                   // </LayersControl.Overlay>
                    //<LayersControl.Overlay name="Chlorophyll">
                     //   <ImageOverlay
                     //       url='https://coastwatch.pfeg.noaa.gov/erddap/griddap/erdMEchla1day.transparentPng?chlorophyll%5B(2012-07-03T12:00:00Z)%5D%5B(0.0)%5D%5B(28.2875):(41.2875)%5D%5B(-80.6375):(-67.6375)%5D&.draw=surface&.vars=longitude%7Clatitude%7Cchlorophyll&.colorBar=%7C%7C%7C%7C%7C&.bgColor=0xffccccff'
                            /*GOES-16 ABI L3C SST (ABI G16-STAR-L3C-v2.70), GOES EAST Position, 0.02°,Hourly, 2017-present
                            *https://coastwatch.pfeg.noaa.gov/erddap/griddap/jplStarG16SSTv270.transparentPng?sea_surface_temperature%5B(2021-03-13T10:00:00Z)%5D%5B(40.99):(25.99)%5D%5B(-87.99001):(-55.99)%5D&.draw=surface&.vars=longitude%7Clatitude%7Csea_surface_temperature&.colorBar=%7C%7C%7C%7C%7C&.bgColor=0xffccccff
                            
                            //    url='https://coastwatch.pfeg.noaa.gov/erddap/griddap/jplStarG16SSTv270.transparentPng?sea_surface_temperature%5B(2021-03-13T10:00:00Z)%5D%5B(40.99):(25.99)%5D%5B(-87.99001):(-55.99)%5D&.draw=surface&.vars=longitude%7Clatitude%7Csea_surface_temperature&.colorBar=%7C%7C%7C%7C%7C&.bgColor=0xffccccff'
                            // url='https://coastwatch.pfeg.noaa.gov/erddap/griddap/nesdisGeoPolarSSTN5NRT.transparentPng?analysed_sst%5B(2021-03-09T12:00:00Z)%5D%5B(25.025):(37.025)%5D%5B(-83.975):(-63.975)%5D&.draw=surface&.vars=longitude%7Clatitude%7Canalysed_sst&.colorBar=%7C%7C%7C%7C%7C&.bgColor=0xffccccff'
                      //      bounds={imageBounds}
                     //  />
                  //  </LayersControl.Overlay>
            ***********************************************************************************************************
          */
        return (

            <MapContainer style={style} center={position} zoom={this.state.zoom} crs={L.CRS.EPSG900913}>
                <LayersControl position="topright">
                    {/* <LayersControl.BaseLayer name="World Map">
                        <TileLayer
                            attribution='NOAA NESDIS CoastWatch East Coast Node'
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        />
                    </LayersControl.BaseLayer> */}
                    <LayersControl.BaseLayer checked name="Ocean">
                        <TileLayer
                            url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.Overlay name="Chlorophyll">
                        <ERDDAPMap product='erdMH1chla8day:chlorophyll'
                            url='https://coastwatch.pfeg.noaa.gov/erddap/wms/erdMH1chla8day/request?'
                        />
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Sea Surface Temps - Daily">
                        <ERDDAPMap product='noaacrwsstanomalyDaily:sea_surface_temperature_anomaly'
                            url='https://coastwatch.noaa.gov/erddap/wms/noaacwecnAVHRRmultisatsstEastCoastMonthly/request?'
                            /*https://coastwatch.noaa.gov/erddap/wms/noaacwBLENDEDsstDaily/request?bgcolor=0x808080&crs=L.CRS.EPSG4326&format=image/png&layers=Land&transparent=true&version=1.3.0*/
                        />
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Sea Surface Temps - Monthly">
                        <ERDDAPMap product='noaacwecnAVHRRmultisatsstEastCoastMonthly:sst'
                            url='https://coastwatch.noaa.gov/erddap/wms/noaacwecnAVHRRmultisatsstEastCoastMonthly/request?'
                            /*https://coastwatch.noaa.gov/erddap/wms/noaacwBLENDEDsstDaily/request?bgcolor=0x808080&crs=L.CRS.EPSG4326&format=image/png&layers=Land&transparent=true&version=1.3.0*/
                        />                        
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Sea Surface Temps - 8 Day Summary">
                        <ERDDAPMap product='erdMH1sstd8dayR20190SQ:sstMasked'
                            url='https://coastwatch.pfeg.noaa.gov/erddap/wms/erdMH1sstd8dayR20190SQ/request?'
                        />
                    </LayersControl.Overlay>
                    {/* <LayersControl.Overlay name="Trips">
                        {this.props.trips.map((trip) => (
                            <TripLine trip={trip} />
                        ))}
                    </LayersControl.Overlay> */}
                </LayersControl>
            </MapContainer>
        )
    }
}