import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, Grid
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getVBWeatherDetails } from '../../util/APIUtils';
import Alert from 'react-s-alert';
import moment from 'moment';
import ChartHourly from '../ChartHourly';
import DetailWeatherTabs from './DetailWeatherTabs';


const useStyles = makeStyles({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: 'rgba(0, 118, 216, 0.53)',
        },
        '& .column-width': {
            width: 150,
        },
    },
});

function getWeatherId(params) {
    console.log(params.row.weatherId);
    return `${params.row.weatherId || ''} `;
}
const columns = [

    {
        field: 'dateTime',
        headerName: 'Date & Time',
        headerClassName: 'super-app-theme--header',
        type: 'number',
        width: 175,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) => {
            const date = moment(params.value).utc().format('MM/DD/YYYY');
            const time = moment(params.value).utc().format('LT');
            return `${date} : ${time} `;
        }
    },
    {
        field: 'airTemp',
        headerName: 'Air Temp',
        headerClassName: 'super-app-theme--header',
        type: 'number',
        width: 125,
        editable: false,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'waveHeight',
        headerName: 'WH',
        headerClassName: 'super-app-theme--header',
        description: 'Significant Wave Height.  This is the average of the highest one-third (33%) of waves (measured from trough to crest) that occur in a given period',
        type: 'number',
        width: 100,
        editable: false,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'wavePeriod',
        headerName: 'WP',
        headerClassName: 'super-app-theme--header',
        description: 'Significant Wave Period',
        type: 'number',
        width: 100,
        editable: false,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'waveDirection',
        headerName: 'WD',
        headerClassName: 'super-app-theme--header',
        description: 'Significant Wave Direction',
        type: 'number',
        width: 100,
        editable: false,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'waterTemp',
        headerName: 'SST',
        description: 'Sea Surface Temparature',
        headerClassName: 'super-app-theme--header',
        type: 'number',
        width: 115,
        editable: false,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'windSpeed',
        headerName: 'WS',
        description: 'Wind Speed',
        headerClassName: 'super-app-theme--header',
        type: 'number',
        width: 100,
        editable: false,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'windDirection',
        headerName: 'WD',
        description: 'Wind Direction',
        headerClassName: 'super-app-theme--header',
        type: 'number',
        width: 175,
        editable: false,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'currentSpeed',
        headerName: 'CS',
        description: 'Current Speed',
        headerClassName: 'super-app-theme--header',
        type: 'number',
        width: 100,
        editable: false,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'currentDirection',
        headerName: 'CD',
        description: 'Current Direction',
        headerClassName: 'super-app-theme--header',
        type: 'number',
        width: 100,
        editable: false,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'swellHeight',
        headerName: 'SH',
        description: 'Swell Height',
        headerClassName: 'super-app-theme--header',
        type: 'number',
        width: 100,
        editable: false,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'swellPeriod',
        headerName: 'SP',
        description: 'Swell Period',
        headerClassName: 'super-app-theme--header',
        type: 'number',
        width: 100,
        editable: false,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'swellDirction',
        headerName: 'SD',
        description: 'Swell Direction',
        headerClassName: 'super-app-theme--header',
        type: 'number',
        width: 100,
        editable: false,
        align: 'center',
        headerAlign: 'center'
    },
];

const rows = [
    { id: 1, airTemp: '95', waveHeight: '3', wavePeriod: 6, waveDirection: 150, waterTemp: 65, windSpead: 15, windDirection: 250, weatherId: 44444 },
    { id: 2, airTemp: '95', waveHeight: '3', wavePeriod: 6, waveDirection: 150, waterTemp: 65, windSpead: 15, windDirection: 250, weatherId: 55555 },
    // { id: 3, airTemp: '95', waveHeight: '3', wavePeriod: 6, waveDirection: 150, waterTemp: 65, windSpead: 15, windDirection: 250 },
    // { id: 4, airTemp: '95', waveHeight: '3', wavePeriod: 6, waveDirection: 150, waterTemp: 65, windSpead: 15, windDirection: 250 },
    // { id: 5, airTemp: '95', waveHeight: '3', wavePeriod: 6, waveDirection: 150, waterTemp: 65, windSpead: 15, windDirection: 250 },
    // { id: 6, airTemp: '95', waveHeight: '3', wavePeriod: 6, waveDirection: 150, waterTemp: 65, windSpead: 15, windDirection: 250 },
    // { id: 7, airTemp: '95', waveHeight: '3', wavePeriod: 6, waveDirection: 150, waterTemp: 65, windSpead: 15, windDirection: 250 },
    // { id: 8, airTemp: '95', waveHeight: '3', wavePeriod: 6, waveDirection: 150, waterTemp: 65, windSpead: 15, windDirection: 250 },

];

export default function WeatherDetailsDialog({ isOpen, handleShowDetails, props, day }) {
    const classes = useStyles();
    const initialValue = new Array();
    const [isDialogOpen, setIsDialogOpen] = React.useState(isOpen);
    let [waypointId, setWaypointId] = React.useState(-1);//-1 for inital load of the page
    const [waypoints, setWaypoints] = React.useState(props.currentUser.waypoints);
    const [weatehrDetails, setWeatherDetails] = React.useState(initialValue);
    const [isLoading, setIsLoading] = React.useState(true);
    // const [dataRows, setDataRows] = React.useState(initialValue);

    useEffect(() => {
        setIsDialogOpen(isOpen);
        // let tmpWaypointId = waypointId;
        console.log('in detailsdialog and is open?=' + isDialogOpen);
        console.log('loading weather details for waypoint=' + waypointId);
        const tmpWaypoints = waypoints && waypoints.filter((waypoint) => waypoint.virtualBuoy === true);
        setWaypoints(tmpWaypoints);
        var user = props.currentUser;
        if (tmpWaypoints.length > 0 && waypointId == -1) {
            waypointId =tmpWaypoints[0].id;
            setWaypointId(waypointId);
        }
        if (isOpen) {//only do this if the dialog is open
            getVBWeatherDetails(user.id, waypointId, day.dateTime)
                .then(response => {
                    console.log(response);
                    setWeatherDetails(response);
                    setIsLoading(false);
                    console.log("got weather details" + weatehrDetails);
                }).catch(error => {
                    Alert.error('Unable to load weather details for waypoint=' + waypointId
                        + '. A possible cause of this is to ensure you have created FINs in the Waypoint tab.');
                });
        }

    }, [isOpen, waypointId, weatehrDetails]);

    const handleClose = (event) => {
        event.stopPropagation();
        setIsDialogOpen();
        handleShowDetails(false);
    };

    const handleWaypointSelection = (event) => {
        event.stopPropagation();
        const value = event.target.value;
        setWaypointId(value);
    };


    return (


        <Dialog open={isDialogOpen} aria-labelledby="form-dialog-title" maxWidth='true' fullWidth='lg'>
                <DialogContent>
                <DialogTitle id="form-dialog-title">FIN Details</DialogTitle>
                <div>
                    <b>Select FIN: </b>
                    <Select
                        labelId="waypoints"
                        id="waypoints"
                        name="waypoints"
                        value={waypointId}
                        onChange={handleWaypointSelection}
                    >
                        {waypoints && waypoints.map((waypoint) => (
                            <MenuItem key={waypoint.id} value={waypoint.id}>{waypoint.name}</MenuItem>
                        ))}
                    </Select>
                </div>
                <div style={{ height: 490, width: '100%' }} className={classes.root}>
                <DataGrid
                        rows={weatehrDetails}
                        columns={columns}
                        rowHeight={25}
                        pageSize={15}
                    />
                {/* {isLoading == true ? 
                    <DataGrid
                        rows={weatehrDetails}
                        columns={columns}
                        rowHeight={25}
                        pageSize={15}
                    />
                    :
                    <DetailWeatherTabs data={weatehrDetails}></DetailWeatherTabs> } */}
                    {/* <ChartHourly data={weatehrDetails}></ChartHourly> */}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                    onClick={(event) => {
                        handleClose(event);
                    }}
                >Close</Button>
            </DialogActions>
        </Dialog>
    );
}