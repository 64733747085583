import React from 'react';
import { VictoryChart, VictoryLegend, VictoryLabel, VictoryVoronoiContainer, VictoryTooltip, VictoryAxis, VictoryLine } from 'victory';
import moment from 'moment';


class CustomLabel extends React.Component {
    // VictoryChart ignores particular roles when calculating a domain
    static role = "label";
    render() {
        const { text, scale, dataX, dataY } = this.props;
        const tmpDate = new Date(dataX);
        const x = scale.x(dataX);
        const y = scale.y(dataY);
        return (
            <VictoryLabel
                x={x}
                y={y + 20}
                text={tmpDate.toDateString()}
                textAnchor="middle"
                verticalAnchor="middle"
                style={[
                    { fill: "red", fontSize: 6 }
                ]}
            />
        );
    }
}
export default function ChartHourly(hourlyData) {

    let xTickValues = hourlyData.map(d => {
        return new Date(d.dateTime);
    });

    return (
        <VictoryChart
            // domainPadding will add space to each side of VictoryBar to
            // prevent it from overlapping the axis
            domainPadding={5}
            scale={{ x: "time" }}
            height={150}
            containerComponent={
                // <VictoryVoronoiContainer/> use just this for tooltip perline
                <VictoryVoronoiContainer voronoiDimension="x"
                //this is necessary to only show the popup as you mouse over the chart for one line vs. one for each line
                    voronoiBlacklist={["windSpeed", "wavePeriod", "swellPeriod", "swellHeight", "currentSpeed"]}
                    //   labels={({ datum }) => `${datum.display} ${datum.y} ${datum.x}`}
                    labels={({ datum }) => ` 
                        Day: ${moment(datum.dateTime).utc().format('ddd')}
                        Date: ${moment(datum.dateTime).utc().format('MM/DD/YYYY')}
                        WH: ${datum.waveHeight} 
                        WP: ${datum.wavePeriod}
                        SH: ${datum.swellHeight}
                        SP: ${datum.swellPeriod}
                        WS: ${datum.windSpeed}
                        CS: ${datum.currentSpeed}
                        `}
                    labelComponent={<VictoryTooltip cornerRadius={0}
                        flyoutStyle={{ fill: "white" }}
                        style={{ fontSize: 6 }} />}
                />
            }
        >
            <VictoryLegend x={50} y={25}
                // title="Legend"
                centerTitle
                orientation="horizontal"
                gutter={20}
                style={{ border: { stroke: "black" }, labels: { fontSize: 6 } }}
                data={[
                    { name: "Wave HT", symbol: { fill: "red" } },
                    { name: "Wave Period", symbol: { fill: "blue" } },
                    { name: "Swell Height", symbol: { fill: "green" } },
                    { name: "Swell Period", symbol: { fill: "yellow" } },
                    { name: "Wind Speed", symbol: { fill: "black" } },
                    { name: "Current Speed", symbol: { fill: "orange" } }
                ]}
            />
            <VictoryAxis
                style={{
                    // axis: { stroke: "#756f6a" },
                    axisLabel: { fontSize: 4, padding: 10 },//comment this out to support tooltip per line
                    grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                    grid: { stroke: "grey" },
                    ticks: { stroke: "grey", size: 5 },
                    tickLabels: { fontSize: 6, padding: 5 }
                }}
            // tickValues={xTickValues}
            // tickFormat={t => new Date(t).getHours()}
            />
            <VictoryAxis dependentAxis
                fixLabelOverlap 
                style={{  
                    // axis: { stroke: "#756f6a" },
                    axisLabel: { fontSize: 4, padding: 30 },
                    // grid: { stroke: ({ tick }) => tick > 1.0 ? "red" : "grey" },
                    grid: { stroke: "grey" },
                    ticks: { stroke: "grey", size: 5 },
                    tickLabels: { fontSize: 6, padding: 5 }
                }}
            />
            {/* {data2.map((item, i) => (
                <CustomLabel
                    key={i}
                    dataX={new Date(item.x)}
                    dataY={0}
                    text="LABEL"
                />
            ))} */}
            <VictoryLine //wave heith line
                data={hourlyData}
                x={d => new Date(d.dateTime)}
                y={d => d.waveHeight}
                style={{
                    data: {
                        stroke: "red",
                        strokeWidth: 1
                    },

                }}
            // labels={({ datum }) => datum.y}
            // labelComponent={<VictoryTooltip/>}//uncomment this to put a tooltip per line
            />
            <VictoryLine name="windSpeed"
                data={hourlyData}
                x={d => new Date(d.dateTime)}
                y={d => d.windSpeed}
                style={{
                    data: {
                        stroke: "black",
                        strokeWidth: 1
                    },
                    labels: {
                        fontSize: 15,
                        fill: ({ datum }) => datum.x === 3 ? "#000000" : "#c43a31"
                    }
                }}
            />
            <VictoryLine name="wavePeriod"
                data={hourlyData}
                x={d => new Date(d.dateTime)}
                y={d => d.wavePeriod}
                style={{
                    data: {
                        stroke: "blue",
                        strokeWidth: 1
                    },
                    labels: {
                        fontSize: 15,
                        fill: ({ datum }) => datum.x === 3 ? "#000000" : "#c43a31"
                    }
                }}
            />     
            <VictoryLine name="swellPeriod"
                data={hourlyData}
                x={d => new Date(d.dateTime)}
                y={d => d.swellPeriod}
                style={{
                    data: {
                        stroke: "yellow",
                        strokeWidth: 1
                    },
                    labels: {
                        fontSize: 15,
                        fill: ({ datum }) => datum.x === 3 ? "#000000" : "#c43a31"
                    }
                }}
            />  
            <VictoryLine name="swellHeight"
                data={hourlyData}
                x={d => new Date(d.dateTime)}
                y={d => d.swellHeight}
                style={{
                    data: {
                        stroke: "green",
                        strokeWidth: 1
                    },
                    labels: {
                        fontSize: 15,
                        fill: ({ datum }) => datum.x === 3 ? "#000000" : "#c43a31"
                    }
                }}
            />      
            <VictoryLine name="currentSpeed"
                data={hourlyData}
                x={d => new Date(d.dateTime)}
                y={d => d.currentSpeed}
                style={{
                    data: {
                        stroke: "orange",
                        strokeWidth: 1
                    },
                    labels: {
                        fontSize: 15,
                        fill: ({ datum }) => datum.x === 3 ? "#000000" : "#c43a31"
                    }
                }}
            />                                             
        </VictoryChart>
    )
}