import React, { useState, useCallback, useEffect, useRef } from 'react'
import L from 'leaflet';
import { MapContainer, TileLayer, LayersControl, Marker, Popup, LayerGroup } from 'react-leaflet'
import { Button, Paper, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TripTree from './TripTree';
import TripLine from './TripLine';
import TripLine2 from './TripLine2';
import AddTripDialog from './AddTripDialog';
import { uploadTrip, getTrips } from '../util/APIUtils';
import Alert from 'react-s-alert';
import { floor, random } from 'mathjs'
import { getImage } from '../img/CFImages';


//have to do all of this because React leaflet for some reason do not include images and you will need to reset default icons image
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

const useStyles = makeStyles((theme) => ({
    root: {
        height: 50,
        flexGrow: 1,
        maxWidth: 400,
    },
    gridBase: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    input: {
        display: 'none',
    },
    box: {
        height: 60,
        display: "flex",
        // border: "1px solid black",
        padding: 4
    },
    topLeftBox: {
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    centerBox: {
        justifyContent: "center",
        alignItems: "center"
    },
    bottomRightBox: {
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    },
    tripMap: {
        padding: theme.spacing(6),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    uploadButton: {
        padding: theme.spacing(2),
        textAlign: 'center',
        // color: theme.palette.text.secondary,
        "@media only screen and (max-width: 550px)": {display: 'none'},
    },
    tripTree: {
        padding: theme.spacing(2),
        textAlign: 'center',
        // color: theme.palette.text.secondary,
        "@media only screen and (max-width: 550px)": {display: 'none'},
    },
}));

const center = ['34.18157', '-76.153560'];




const WaypointMarker = ({ waypoint }) => {
    const markerRef = useRef(null);
    const [markerIcon, setMarkerIcon] = useState(icon)
    const [markerIconSize, setMarkerIconSize] = useState()

    useEffect(() => {
        var newIcon = getImage(waypoint.waypointTypeId.image);
        setMarkerIcon(newIcon.img);
        setMarkerIconSize(newIcon.size);
    }, []);

    const parkIcon = new L.Icon({
        iconUrl: markerIcon,
        iconSize: markerIconSize,
        popupAnchor: [0, -15],
        shadowUrl: iconShadow,
        shadowAnchor: [13, 28]
    });

    return (
        <Marker
            position={[waypoint.latitude, waypoint.longitude]}
            icon={parkIcon}
            key={waypoint.id}
        >
            <Popup minWidth={90}>
                {waypoint.name}
                <br></br>
                {waypoint.latitude}
                <br></br>
                {waypoint.longitude}
                <br></br>
                {/* <Button type="submit" color="primary" variant="outlined" onClick={handleDelete}>
                    Delete
                </Button>
                <Button color="primary" variant="outlined" onClick={openDialog}>Edit</Button> */}
                <br></br>
                {/* <span style={style} onClick={toggleDraggable}>
                    {draggable
                        ? 'Click here to make marker not draggable'
                        : 'Click here to make marker draggable'}
                </span> */}
            </Popup>
        </Marker>
    )
}

export default function TripMap(props) {
    const classes = useStyles();
    const [position, setPosition] = useState(center)
    const [isFileUploadDlgOpen, setIsFormUploadDlgOpen] = useState(false);
    const [trips, setTrips] = useState();
    const [waypoints, setWaypoints] = useState();
    const tripRef = useRef(null)

    useEffect(() => {
        var user = props.currentUser;
        setWaypoints(user.waypoints);
        //set the default center of the map for the user
        if (user.waypoints && user.waypoints.length > 0) {
            const tmpPos = [user.waypoints[0].latitude, user.waypoints[0].longitude];
            setPosition(tmpPos);
        }
        getTrips(user.id)
            .then(response => {
                // Alert.success("You're successfully logged in!");
                setTrips(response);
                console.log("loaded trips:  " + response);
                // this.props.history.push("/");
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }, []);

    const handleOpenFileUpload = (event) => {
        setIsFormUploadDlgOpen(true);
    }

    const updateParent = useCallback(event => {
        setIsFormUploadDlgOpen(event);
    }, [trips])

    const addTrip = useCallback(trip => {
        trips.push(trip);
        setTrips(trips);
    }, [trips])

    const deleteTrip = useCallback(trip => {
        const index = trips.indexOf(trip);
        if (index != -1) {
            trips.splice(index, 1);
        }
        setTrips(trips);
    }, [trips, tripRef])


    const style = {
        width: '75vw',
        height: '75vh',
        'marginLeft': 'auto',
        'marginRight': 'auto',
        top: 'center'
    }

    const treeStyle = {
        'margin-top': '50px'
    }

    return (

        <div className={classes.gridBase}>
            <Grid direction='row' container spacing={1}>
                <Grid style={treeStyle} container direction='column' item sm={2}>

                    <Paper className={classes.uploadButton}>
                        <Button color="primary" variant="contained"
                            onClick={handleOpenFileUpload}>Create Trip</Button>
                    </Paper>

                    <Paper className={classes.tripTree}>
                        <TripTree trips={trips} deleteTrip={deleteTrip} />
                    </Paper>

                </Grid>
                <Grid container item sm={6}>
                    <Paper className={classes.paper}>
                        <MapContainer style={style} center={position} zoom={8} crs={L.CRS.EPSG900913}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <LayersControl position="topright">
                                {/* <LayersControl.BaseLayer name="World Map">
                                    <TileLayer
                                        // attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                    />
                                </LayersControl.BaseLayer> */}
                                {/* <LayersControl.BaseLayer checked name="Ocean">
                                    <TileLayer
                                        // attribution='Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri'
                                        url="https://services.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}"
                                    />
                                </LayersControl.BaseLayer> */}
                                <LayersControl.BaseLayer checked name="Ocean">
                                    <TileLayer
                                        attribution="Google Maps Satellite"
                                        url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                                    />
                                </LayersControl.BaseLayer>
                                {<LayersControl.Overlay checked name="My Waypoints">
                                    <LayerGroup>
                                        {waypoints && waypoints.length > 0 ? waypoints.map((waypoint) => (
                                            <WaypointMarker waypoint={waypoint} key={waypoint.id}></WaypointMarker>
                                        )) : <span></span>}

                                    </LayerGroup>
                                </LayersControl.Overlay>}
{/* 
                                    {trips && trips.length > 0 ? trips.map((trip) => (
                                        <LayersControl.Overlay checked name={trip.name}>
                                            <TripLine2 key={trip.tripID} trip={trip} />
                                        </LayersControl.Overlay>
                                    )) : <span></span>} */}
                                    <TripLine2 trips={trips} ref={tripRef} />

                            </LayersControl>
                            <AddTripDialog isOpen={isFileUploadDlgOpen} updateParent={updateParent} addTrip={addTrip}></AddTripDialog>

                        </MapContainer>

                    </Paper>
                </Grid>
            </Grid>
        </div>
    )



}