//this is to support upgrade from v4 to v5 and wrap the IdleTimer
//so we could use it as it was in v4 without a lot of code changes
import { Component } from 'react'
  import { withIdleTimer } from 'react-idle-timer'

  class IdleTimerComponent extends Component {
    render () {
      return this.props.children
    }
  }

  export const IdleTimer = withIdleTimer(IdleTimerComponent)