import React, { useState, useEffect } from 'react'
import { Grid, Card, Avatar, CardHeader, Collapse, CardContent, Typography, CardActions, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { red, blue, grey } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { GRID_COLUMN_HEADER_SEPARATOR_RESIZABLE_CSS_CLASS } from '@mui/x-data-grid';
import StandardSubscription from './StandardSubscription';
import ProfessionalSubscription from './ProfessionalSubscription';

// import "./stripeStyle.css";

const useStyles = makeStyles((theme) => ({
    subscriptionContainer: {
        marginTop: '40px',
    },
    subScriptionContent: {
        background: "#fff",
        boxShadow: "0 1px 11px rgba(0, 0, 0, 0.27)",
    },
    subsciptionGrid: {
        boxShadow: "0 1px 11px rgba(0, 0, 0, 0.27)",
        width: 700,
        // height: 400,
        display: 'flex',
    },
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatarBeginner: {
        backgroundColor: grey[500],
    },
    avatarPro: {
        backgroundColor: grey[900],
    },
    avatarTournament: {
        backgroundColor: blue[500],
    },
    price: {
        color: blue[500],
        variant: 'h4'
    },
    subCard: {
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // padding: '0 10px',
        justifyContent: 'space-between',
    },

}));

export default function Subscription(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [open, setOpen] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        // <StripeButton price={200}></StripeButton>
        // <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="lg">
        // <DialogTitle id="form-dialog-title">Subscribe to ChasinFins</DialogTitle>
        //     <DialogContent>
        //         </DialogContent>
<div className={classes.subscriptionContainer}>
        <Grid sx={{ flexGrow: 1 }} container spacing={2} justifyContent='center' className={classes.subscriptionContainer}>
            {/* Beginner subscription group */}
            {/* <Grid item className={classes.subsciptionGrid}>
                <Card>
                    <CardHeader
                        // avatar={
                        //     <Avatar aria-label="recipe" className={classes.avatarBeginner}>
                        //         B
                        //     </Avatar>
                        // }
                        // action={
                        //     <IconButton aria-label="settings">
                        //         <Button>test</Button>
                        //     </IconButton>
                        // }
                        title="Beginner"
                        subheader="For the basic fisherman that only fishes a few times a year."
                    />
                    <CardContent>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item>
                                <StandardSubscription {...props}></StandardSubscription>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container justifyContent="center" >
                            <Button variant="contained" justify="center" color="primary" component="span" onClick={handleExpandClick}>Details</Button>
                        </Grid>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>
                                This is the most basic fishing data provided.  Here we do not do any personalized fishing analysis for you but simply capture
                                weatehr data off of the "Fishing Intelligent Network" you have setup.
                            </Typography>
                            <Typography paragraph>
                                You are also  limited to uploads of trip and waypoint info.
                                These are more advanced features that allow us to build personalized fishing networks for you.  Look at the Adavaced and Professional
                                subscriptions if you want to really build out a fishing network that can help you target fish.
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid> */}
            {/* Advance subscription group */}
            <Grid item className={classes.subsciptionGrid} >
                <Card>
                    <CardHeader
                        title="Premium Fishing Service"
                        subheader="Get the most detailed fishing analysis."
                    />
                    <CardContent>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item>
                                <ProfessionalSubscription {...props}></ProfessionalSubscription>
                            </Grid>

                        </Grid>

                    </CardContent>
                    <CardActions>
                        <Grid container justifyContent="center" >
                            <Button variant="contained" justify="center" color="primary" component="span" onClick={handleExpandClick}>Details</Button>
                        </Grid>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                        <Typography paragraph>
                                <b>Personalized Weather Data:</b>  Create your own waypoints that we will then pull weather, tide and astronomy data for you.  Each waypoint becomes a part
                                of your personal Fishing Intellegent Network or (FINs).  These are then used to pull data that provides your realtime infomration
                                on your favorite locations.  
                            </Typography>
                             <Typography paragraph>
                                <b>Fishing Log Book:  </b>If you are like many fisherman you find yourself wondering where did I fish last year or the year before.  
                                Trip Logs allow you to upload your trip data/route information for future analysis and review.  Well now you can have it all in one place.  Upload your
                                log info to our app and we will save it for you to review at anytime.  
                            </Typography>
                            <Typography paragraph>
                                <b>FINs</b> (Fishing Intellegent Networks) are a group of waypoints that we use to analyis fishing data to provide you
                                the most up-to-date infomration for your target areas.  
                            </Typography>
                        </CardContent>
                    </Collapse>

                </Card>

            </Grid>

        </Grid>
        </div>
    );
}