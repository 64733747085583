import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

// import icon from "./constants";

// Cordinates of Marcillac
const center = [35, -77];
const purpleOptions = { color: "white" };
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

function LeafletgeoSearch({parentCallback}) {
  const map = useMap();

  const handleLocationSelected = (event) => {
    console.log("Location selected");
    parentCallback('homeLocation', event.location.label);
  }

  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      showMarker: true,
      showPopup: true,
      style: 'bar',
      marker: {
        icon: DefaultIcon
      }
    });

    map.addControl(searchControl);
    map.on('geosearch/showlocation', handleLocationSelected);

    return () => map.removeControl(searchControl);
  }, []);

  return null;
}

function LocationMarker() {
  const [position, setPosition] = useState(null)
  const map = useMapEvents({
    click() {
      // console.log('location onclick')
      map.locate();
    },
    locationfound(e) {
      console.log('location found:', e)
      setPosition(e.latlng)
      map.flyTo(e.latlng, map.getZoom())
    },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  )
}

function AddMarker({handleUpdateParentState, parentMapTextField}) {
  const [markerPos, setMarkerPos] = useState(null)
  const map = useMapEvents({
    click(e) {
      console.log('add marker onclick' + parentMapTextField)
      setMarkerPos(e.latlng)
      handleUpdateParentState(parentMapTextField, e.latlng.lat + ", " + e.latlng.lng)
      map.locate();
    },
    // locationfound(e) {
    //   console.log('location found:', e)
    //   // setMarkerPos(e.latlng)
    //   // map.flyTo(e.latlng, map.getZoom())
    // },
  });

  return markerPos === null ? null : (
    <Marker position={markerPos}>
      <Popup>Lat:{markerPos.lat}<br></br>
        Long:{markerPos.lng}
      </Popup>
    </Marker>
  )
}

function MapSearcher(props) {

    return (
      <MapContainer
        center={center}
        zoom={3}
        scrollWheelZoom={true}
        style={{ height: "50vh" }}
      >
        <TileLayer
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {props.isClickable ? <AddMarker handleUpdateParentState={props.handleUpdateParentState} parentMapTextField={props.parentTextFieldState}/> : 
          <LeafletgeoSearch parentCallback={props.handleUpdateParentState} parentMapTextField={props.parentTextFieldState}/> }
        {/* <LeafletgeoSearch parentCallback={props.handleSelection} /> */}
        {/* <LocationMarker/> */}
        {/* <AddMarker/> */}

      </MapContainer>
    );
}

export default MapSearcher;
