import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import moment from 'moment';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import iconCodeMapping from '../../WeatherIcon';
import Alert from 'react-s-alert';
import WeatherDetailDayCard from './WeatherDetailDayCard';
import Collapse from "@mui/material/Collapse";
import { Grid } from '@mui/material';

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
    // width: '60%'
  },
  media: {
    height: '3rem',
    width: '3rem'
  },
  muiCareRoodSameHeight: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default function WeatherDayCard({ day, index }) {
  const classes = useStyles();
  const initialValue = new Array();
  const [weatherAvgByVirtuatlBuoy, setWeatherAvgByVirtuatlBuoy] = useState(initialValue);

  useEffect(() => {
    //want to check to make sure that the daily average is loaded per day 
    var dayAvgs = day.weatherAvgByVirtuatlBuoy[0];///should always only be one
    if (dayAvgs != null) {
      setWeatherAvgByVirtuatlBuoy(dayAvgs);
    } else {
      Alert.error('Unable to load daily average for FINs. A possible cause of this is to ensure you have created FINs in the Waypoint tab.');
    }
  }, []);
  return (
    <Card className={classes.muiCareRoodSameHeight} key={weatherAvgByVirtuatlBuoy.id} >
      <CardActionArea>
        <CardMedia  uncomment when we have this figured out
          className={classes.media}
          // image={iconCodeMapping.thumbsUp}
          title="Weather Image"
        />
        <CardContent>
          <Grid>
          <Typography gutterBottom variant="subtitle1" component="div" align="justify">
            <b>{day.dayOfWeek}</b> click for details
          </Typography>
          </Grid>
          <Typography variant="body5" color="textSecondary" component="div" align="justify">
            {moment(weatherAvgByVirtuatlBuoy.dateTime).utc().format('MM/DD/YYYY')}
          </Typography>
          <Typography variant="body5" color="textSecondary" component="div" align="justify">
            Temp:{weatherAvgByVirtuatlBuoy.airTemp}
          </Typography>
          <Typography variant="body6" color="textSecondary" component="div" align="justify">
            WH:{weatherAvgByVirtuatlBuoy.waveHeight}
          </Typography>
          <Typography variant="body6" color="textSecondary" component="div" align="justify">
            WP:{weatherAvgByVirtuatlBuoy.wavePeriod}
          </Typography>
          <Typography variant="body6" color="textSecondary" component="div" align="justify">
            WS:{weatherAvgByVirtuatlBuoy.windSpeed}
          </Typography>
          <Typography variant="body6" color="textSecondary" component="div" align="justify">
            WD:{weatherAvgByVirtuatlBuoy.windDirection}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            SST:{weatherAvgByVirtuatlBuoy.waterTemp}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            CS:{weatherAvgByVirtuatlBuoy.currentSpeed}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            CD:{weatherAvgByVirtuatlBuoy.currentDirection}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <Collapse in={expandedId === index} timeout="auto" unmountOnExit>
        <CardContent>
          <div>ActivitiesList</div>
        </CardContent>
      </Collapse> */}
    </Card>
  );
}