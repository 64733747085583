import React, { useState, memo, useCallback } from 'react';
import { FixedSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoizeOne from 'memoize-one';
import WaypointDialog from './WaypointDialog';

const Row = memo(({ data, index, style }) => {
    const { flattenedData, onOpen, onSelect } = data;
    const node = flattenedData[index];
    const left = node.depth * 20;
    return (
        <div className="item-background" style={style} onClick={() => onOpen(node)}>
            <div
                className={`${node.haswaypoints ? 'tree-branch' : ''} ${node.collapsed ? 'tree-item-closed' : 'tree-item-open'}`}
                onClick={e => onSelect(e, node)}
                style={{
                    position: 'absolute',
                    left: `${left}px`,
                    width: `calc(100% - ${left}px)`,
                }}
            >
                {`${node.label}`}
            </div>
        </div>
    );
}, areEqual);

const getItemData = memoizeOne((onOpen, onSelect, flattenedData) => ({
    onOpen,
    onSelect,
    flattenedData,
}));

const SpeedTree = ({ waypoints, updateParentState,props } ) => {
    const [openedNodeIds, setOpenedNodeIds] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [waypoint, setWaypoint] = React.useState();


    const flattenOpened = treeData => {
        const result = [];

        for (let node of waypoints) {
            flattenNode(node, 1, result);
        }
        return result;
    };

    // const flattenFins = (results) => {
    //     const finsParentId = 0;
    //     results.push({
    //         finsParentId,
    //         label,
    //         hasChildren: children && children.length > 0,
    //         depth,
    //         collapsed,
    //     })
    //     waypoints.filter(waypoint => waypoint.virtualBuoy === true).map(waypoint => {

    //     })
    // }

    const flattenNode = (node, depth, result) => {
        const { id, name, waypoints } = node;
        const label = node.name;
        let collapsed = !openedNodeIds.includes(id);
        result.push({
            id,
            label,
            haswaypoints: waypoints && waypoints.length > 0,
            depth,
            collapsed,
            node,
        });

        if (!collapsed && waypoints) {
            for (let child of waypoints) {
                flattenNode(child, depth + 1, result);
            }
        }
    };

    const onOpen = node => (node.collapsed ? setOpenedNodeIds([...openedNodeIds, node.id]) : setOpenedNodeIds(openedNodeIds.filter(id => id !== node.id)));

    const onSelect = (e, node) => {
        //e.stopPropagation();
        if (node.node.id == -1 || node.node.id == -2){
            return;
        }
        setWaypoint(node.node);
        setOpen(true);
        console.log('item selected');
    };

    const flattenedData = flattenOpened(waypoints);

    const itemData = getItemData(onOpen, onSelect, flattenedData);

    const discard = useCallback(event => {
        setOpen(false);
    }, [])

    const handleUpdateState = useCallback(waypoint => {
        setOpen(false);
        setWaypoint(waypoint); 
        updateParentState(waypoint);
    }, [waypoints])

    return (
        //this div is necessary to give the AutoSizer access to the width and height.  Without it AutoZizer does not work and the tree is not populated
        <div style={{ flex: '1 1 auto', height: '65vh' }}>
            <AutoSizer>
                {({ height, width }) => {
                    console.log("auto sizer info= " + height, width)
                    return (<List className="List" height={height} itemCount={flattenedData.length} itemSize={32} width={width} itemKey={index => flattenedData[index].id} itemData={itemData}
                    >
                        {Row}
                    </List>)
                }}
            </AutoSizer>
            <WaypointDialog doOpen={open} waypoint={waypoint} updateParentState={handleUpdateState} waypoints={waypoints} discard={discard} props={props}></WaypointDialog>
        </div>

        // <AutoSizer>
        //   {({ height, width }) => (
        //     <List className="List" height={height} itemCount={flattenedData.length} itemSize={32} width={width} itemKey={index => flattenedData[index].id} itemData={itemData}>
        //       {Row}
        //     </List>
        //   )}
        // </AutoSizer>
    );
};

export default SpeedTree;
