export const COPYRIGHT = 'ChasinFins';

//use these for dev environment
// export const API_BASE_URL = 'https://dev.chasinfins.com/chasinfins/web';
// export const API_WEATHER_URL = 'https://dev.chasinfins.com/chasinfins/weather';  
export const API_BASE_URL = 'http://localhost/chasinfins/web';
export const API_WEATHER_URL = 'http://localhost:8888/chasinfins/weather';
export const ACCESS_TOKEN = 'accessToken';

export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect'

export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const WATERTYPE_SALTWATER = 'saltwater';
export const WATERTYPE_FRESHWATER = 'freshwater';
export const WATERTYPE_INSHORE = 'inshore';
export const WATERTYPE_OFFSHORE = 'offshore';