import React, { useState, useEffect, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import moment from 'moment';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import iconCodeMapping from '../../WeatherIcon';
import Alert from 'react-s-alert';
import { Grid, Button } from '@mui/material';
import WeatherDetailsDialog from './WeatherDetailsDialog';

const useStyles = makeStyles({
    root: {
        // maxWidth: 345,
        width: 650
    },
    media: {
        height: '3rem',
        width: '3rem'
    },
});

export default function WeatherDetailDayCard({ day, handleShowingDetails, props }) {
    const classes = useStyles();
    const initialValue = new Array();
    const [weatherAvgByVirtuatlBuoy, setWeatherAvgByVirtuatlBuoy] = useState(initialValue);
    const [isVBDetailsDlgOpen, setIsVBDetailsDlgOpen] = React.useState(false);

    useEffect(() => {
        console.log('is details open=' + isVBDetailsDlgOpen);
        //want to check to make sure that the daily average is loaded per day 
        var dayAvgs = day.weatherAvgByVirtuatlBuoy[0];///should always only be one
        if (dayAvgs != null) {
            setWeatherAvgByVirtuatlBuoy(dayAvgs);
        } else {
            Alert.error('Unable to load daily average for FINs. A possible cause of this is to ensure you have created FINs in the Waypoint tab.');
        }
    }, []);


    const handleShowDetails = useCallback(event => {
        handleShowingDetails(false);
        setIsVBDetailsDlgOpen(event);
    }, [])

    const handleOpenDetails = (event) => {
        console.log('is details open=' + isVBDetailsDlgOpen);
        event.stopPropagation();
        setIsVBDetailsDlgOpen(true);
        handleShowingDetails(true);
        console.log('is details open=' + isVBDetailsDlgOpen);
    }

    return (

        <Card className={classes.root} key={weatherAvgByVirtuatlBuoy.id}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    // image={iconCodeMapping.thumbsUp}
                    title="Day Average"
                />
                <CardContent>
                    <Grid>
                        <Typography gutterBottom variant="subtitle1" component="div" align="justify">
                            {day.dayOfWeek} - {moment(weatherAvgByVirtuatlBuoy.dateTime).utc().format('MM/DD/YYYY')}
                            <Button variant="contained"
                                onClick={(event) => {
                                    handleOpenDetails(event);
                                }}
                            >Details</Button>
                        </Typography>
                    </Grid>
                    <Grid container xs={12} spacing={1}>
                        {/* <Grid item container direction="row"> */}
                        {day.weatherAvgEvery3HrPerDay.map((weather, i) => (
                            //setting breakpoints to autosize by not giving a value
                            <Grid item xs sm md lg xl>
                                <Typography style={{ color: "#00adb5" }} variant="caption" color="textSecondary" component="div" align="justify">
                                    {moment(weather.dateTime).utc().format('HH:MM')}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" component="div" align="justify">
                                    Temp:{weather.airTemp}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" component="div" align="justify">
                                    WH:{weather.waveHeight}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" component="div" align="justify">
                                    WP:{weather.wavePeriod}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" component="div" align="justify">
                                    WS:{weather.windSpeed}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" component="div" align="justify">
                                    WD:{weather.windDirection}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" component="p">
                                    SST:{weather.waterTemp}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" component="p">
                                    CS:{weather.currentSpeed}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" component="p">
                                    CD:{weather.currentDirection}
                                </Typography>
                            </Grid>
                        ))}
                        <WeatherDetailsDialog isOpen={isVBDetailsDlgOpen}
                            handleShowDetails={handleShowDetails}
                            props={props}
                            day={day}>
                        </WeatherDetailsDialog>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>

    );
}