import React, { Component } from 'react';
import './Home.css';
import marlin from '../img/marlin.jpg';
import bass from '../img/bass.png';
import dailyDashboard from '../img/daily dashboard.png';
import customProfile from '../img/customProfile.png';
import makeStyles from '@mui/styles/makeStyles';
import waypoints from '../img/waypoints.png';
import chasinfins from '../img/Chasinfins-logo-black-on-white.jpg'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Box } from '@mui/material';

class Home extends Component {


    render() {

        const styleObj = {
            fontSize: 40,
            color: "#3396FF",
            textAlign: "center",
            paddingTop: "15px",
        }
        const styleObj2 = {
            display: "inline",
            textAlign: "left",
        }
        const styleObj3 = {
            float: "right"
        }
        return (
            <div className="home-container">
                
                <div className="container">
                    <div className="image">
                        <img src={marlin} />
                    </div>
                    <div className="text">
                    <p style={styleObj}><i><b>Welcome to ChasinFins where the future of fishing and AI meet!</b></i></p>
                    </div>
                    <div className="image">
                        <img src={bass} />
                    </div>
                </div>
                <Box className="box">
                    <Box>
                    <div className="container">
                    <div className="text">
                        <h3><i>ChasinFins is the first in the market that uses real time data from forecasts, astronomy, and tides to show up-to-date fishing information tailored to you.
                            That's right ChasinFins allows you to manage your own waypoints and fishing trip data and we bring the technology!  Whether you are an inshore/offshore to a lake fisherman you create your own Fishing Intelligent
                            Networks (waypoints) that allows you to see your personalized up-to-date forcasting models and targeted hotspots.</i></h3>
                    </div>
                </div>
                    </Box>
                    <Box className="box">
                    <p className='text' >Your daily dashboard pulls data specifically for you based on your Fishing Intelligent
                            Networks (waypoints)</p>
                        <img src={dailyDashboard} />
                    </Box>
                    <Box className="box">
                    <p className='text'>Manage all our waypoints in one place.  You can wasily create or upload your waypoints.</p>
                        <img src={waypoints} />
                    </Box>
                </Box>
            </div>

        )
    }
}

export default Home;