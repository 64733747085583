import React, { useState, useEffect, useRef } from 'react'
import L from 'leaflet';
import { Polyline, useMapEvents, LayersControl } from 'react-leaflet'

import Alert from 'react-s-alert';
import { floor, random } from 'mathjs'

const myRouteTest = [
    [34.452013, -75.821677],
    [34.457749, -75.823401],
    [34.462844, -75.827613],
    [34.467903, -75.831405],
    [34.469101, -75.825433],
    [34.472537, -75.826517],
    [34.46738, -75.823303],
    [34.466348, -75.821663],
    [34.462159, -75.815665],
    [34.457858, -75.809724],
    [34.453227, -75.806552],
    [34.450229, -75.803527],
    [34.444998, -75.798925],
    [34.439271, -75.796441],
    [34.433141, -75.796452],
    [34.482771, -75.718814],
    [34.51903, -76.442413],
    [34.519628, -76.465259],
    [34.521005, -76.48865],
    [34.525232, -76.508717]
]

function CFTripPolyline(props) {
    const { trip } = props;
    const [linePositions, setLinePositions] = useState([]);
    const [color, setColor] = useState();
    const cfLineRef = useRef(null);


    useEffect(() => {
        const tempLatLong = [];
        //NOTE:  Had an issue with the lines not plotting correctly.  The issue is the sort
        //from the fetch of TripWaypoints were not ordered by tripWaypointID
        trip.tripWaypoint.map(waypoint =>
            tempLatLong.push([Number(waypoint.latitude), Number(waypoint.longitude)])
        );
        setLinePositions(tempLatLong);
        //dynamically set the line color for the trip
        setColor({ color: getColor() });

    }, []);

    const map = useMapEvents({
        load: () => {
            console.log("map load event");
        }
    })

    const getColor = () => {
        console.log("getting color");
        var r = floor(random() * 255);
        var g = floor(random() * 255);
        var b = floor(random() * 255)
        return "rgb(" + r + " ," + g + "," + b + ")";
    }

    return (
        <div>
            {
                (linePositions && linePositions.length > 0) ? <Polyline pathOptions={color} positions={linePositions} ref={cfLineRef} /> : null
            }
        </div>
    )
}
export default function TripLine2(props) {
    const { trips } = props;
    const [linePositions, setLinePositions] = useState([]);
    const [color, setColor] = useState();
    const cfLineRef = useRef(null);



    return (
        <div>
            {/* {
                (linePositions && linePositions.length > 0) ? <Polyline pathOptions={color} positions={linePositions} /> : null
            } */}
                
                {trips && trips.length > 0 ? trips.map((trip) => (
                    <LayersControl.Overlay checked name={trip.name}>
                        <CFTripPolyline key={trip.tripID} trip={trip} ref={cfLineRef}/>
                    </LayersControl.Overlay>
                )) : <span></span>}

        </div>
    )
}
