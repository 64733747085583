// import React from 'react';
import React, { Component } from 'react';
import {
    Box, FormControl, FormLabel, RadioGroup, Typography, Radio, Divider, Container, TextField, FormControlLabel, CssBaseline, Button, Avatar, Link, Grid, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import withStyles from '@mui/styles/withStyles';
import { WATERTYPE_FRESHWATER, WATERTYPE_SALTWATER, WATERTYPE_INSHORE, WATERTYPE_OFFSHORE, COPYRIGHT } from '../../constants';
import MapSearcher from './MapSearcher';
import WeatherProfile from './WeatherProfile';
import { saveProfile } from '../../util/APIUtils';
import Alert from 'react-s-alert';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/" underline="hover">
                {COPYRIGHT}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        maxWidth: 250
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class Profile2 extends Component {

    constructor(props) {
        console.log('Profile2 constructor');
        super(props);
        this.state = {
            waterType: props.currentUser.profile.waterType,
            inshoreOffshore: props.currentUser.profile.inshoreOffshore,
            showMap: false,
            isMapClickable: false, //used when opening map dialog if user can click on the map and add a marker for fishing spots
            homeLocation: props.currentUser.profile.homeLocation,
            fishSpot1: '',
            fishSpot2: '',
            mapTextField: '' //this is the name of the state to update from the map once a selection is made
        };
        console.log("cancel URL=" + props.currentUser.subscription.manageSubUrl);
        //this ref is for the 
        this.homeTxtFldRef = React.createRef();
        //we have to bind the submit to get it to have all the values
        //https://swsinswsin.medium.com/typeerror-cannot-read-property-state-of-undefined-ab77e924f992
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        this.setState({
            waterType: '',
            inshoreOffshore: '',
            showMap: false,
            isMapClickable: false,
            homeLocation: '',
            fishSpot1: '',
            fishSpot2: ''
        });
    }

    launchMap(isClickable, textField, e) {
        console.log("launching map");
        this.setState({
            showMap: !this.state.open,
            isMapClickable: isClickable,
            mapTextField: textField
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const profileRequest = Object.assign({}, this.state);
        var user = this.props.currentUser;

        saveProfile(profileRequest, user.id)
            .then(response => {
                console.log('User profile updated');
                this.setState({ user: response });
                Alert.success("Profile saved successfully");
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }

    render() {
        const { classes } = this.props;

        const handleChange = (event) => {
            const key = event.target.name;
            const value = event.target.value;
            this.setState({ [key]: value });
            console.log(value);
            console.log('target name=' + key);
        };

        const handleMapClose = (event) => {
            console.log("closing map");
            this.setState({ showMap: false });
            // since this is a component that's not mounted when the top-level component loads
            //had to add this timeout to trigger it focus event on the Material UI component
            // setTimeout(
            //     function () {
            //         this.homeTxtFldRef.current.focus();
            //     }.bind(this),
            //     500); // Change to duration of the animation
        }
        const handleLocationSelection = (event) => {
            console.log('updating home location-' + event.location.label);
            this.setState({ homeLocation: event.location.label });
        }

        /**currently using this for sliders on the Weather profile as there eventing seems different
         * and the event.target does not have name attribute
         */
        const handleUpdateParentState = (key, value) => {
            //we have to handle the fishing spot array
            if (key == 'fishSpot1' || key == 'fishSpot2') {
                console.log('updating fishing spot-' + key);
                this.setState({ [key]: value });///for form field only
            } else {
                console.log('updating normal state-' + key + ' ' + value);
                this.setState({ [key]: value });
            }
        }
        return (
            <Container component="main" maxWidth="xs" >
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar} alt={this.props.currentUser.name && this.props.currentUser.name[0]}
                        src={this.props.currentUser.imageUrl}>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        My Profile
                    </Typography>
                    <form onSubmit={this.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="fullname"
                                    name="fullname"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="fullname"
                                    label="Full Name"
                                    autoFocus
                                    value={this.props.currentUser.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    // autoComplete="state"
                                    // inputRef={this.homeTxtFldRef}
                                    name="homeLocation"
                                    variant="outlined"
                                    disabled
                                    required
                                    fullWidth
                                    id="homeLocation"
                                    label="Home City, State"
                                    // autoFocus
                                    value={this.state.homeLocation}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={5} sm={4}>

                                <Button
                                    // type="submit"
                                    // fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => this.launchMap(false, 'homeLocation', e)}>
                                    Find
                                </Button>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend"><Typography variant="h6">What type of water do you primarily fish in?</Typography></FormLabel>
                                    <RadioGroup aria-label="waterType" name="waterType" value={this.state.waterType} onChange={handleChange}>
                                        <FormControlLabel value={WATERTYPE_SALTWATER} control={<Radio />} label={WATERTYPE_SALTWATER} />
                                        <FormControlLabel value={WATERTYPE_FRESHWATER} control={<Radio />} label={WATERTYPE_FRESHWATER} />
                                    </RadioGroup>
                                </FormControl>

                                {this.state.waterType == WATERTYPE_SALTWATER &&
                                    <Grid item>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend"><Typography variant="h6">Near Shore or Offshore?</Typography></FormLabel>
                                            <RadioGroup aria-label="inshoreOffshore" name="inshoreOffshore" value={this.state.inshoreOffshore} onChange={handleChange}>
                                                <FormControlLabel value={WATERTYPE_INSHORE} control={<Radio />} label="Inshore" />
                                                <FormControlLabel value={WATERTYPE_OFFSHORE} control={<Radio />} label="Offshore" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                }

                            </Grid>
                        </Grid>
                        <Grid>
                            <br></br><Divider /><br></br>
                            <WeatherProfile handleChange={handleChange} handleUpdateParentState={handleUpdateParentState} user={this.props.currentUser} />
                        </Grid>
                        <Grid>
                        <br></br><Divider /><br></br>
                            <Grid container spacing={2}>
                                {/* <Grid item xs={12} sm={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}>
                                        Cancel
                                    </Button>
                                </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    <Button href={this.props.currentUser.subscription.manageSubUrl}
                                        variant="contained"
                                        color="primary"
                                        >
                                        Cancel Subscription
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary">
                                        Save
                                    </Button>
                                </Grid>                                
                            </Grid>
                        </Grid>
                    </form>
                    <Dialog open={this.state.showMap}
                        onClose={handleMapClose}
                        aria-labelledby="form-dialog-title"
                        fullWidth="true"
                        maxWidth="xl"
                        onClose={this.handleCityStateSelection}>
                        {console.log('launching map=' + this.state.showMap)}
                        <DialogTitle id="form-dialog-title">Choose Location</DialogTitle>
                        <DialogContent >
                            <DialogContentText>
                                Use the map to search for your location:
                            </DialogContentText>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MapSearcher handleSelection={handleLocationSelection} handleUpdateParentState={handleUpdateParentState} parentTextFieldState={this.state.mapTextField} isClickable={this.state.isMapClickable} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleMapClose} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
        )
    }
}

export default withStyles(useStyles)(Profile2)