// import * as React from 'react';
import React, { useState, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import { CircularProgress } from '@mui/material';

// mock data for testing without API call
import testData from './weather/testData.json';

import WeatherWidget from './weather/WeatherWidget';
import { blue } from '@mui/material/colors';

const useStyles = withStyles((theme) => ({
    mybox: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
    },
}));


export default function Dashboard(props) {


    return (
        
        <WeatherWidget props={props}> 

        </WeatherWidget>
        // <Box display={'flex'}>
        //     <CssBaseline />
        //     <Box
        //         component="main"
        //         flexGrow={1}
        //         height={'100vh'}
        //         overflow={'auto'}
        //         bgcolor={(theme) =>
        //             theme.palette.mode === 'light'
        //                 ? theme.palette.grey[100]
        //                 : theme.palette.grey[900]}
        //     >
        //         <Container maxWidth="lg" mt={4} mb={4}  >
        //             <Grid container spacing={3}>
        //                 {/* Chart */}
        //                 <Grid item xs={2} md={8} lg={5}>
        //                     <Paper p={2} display={'flex'} flexDirection={'column'} height={'240'}>
        //                         <Chart />
        //                     </Paper>
        //                 </Grid>
        //                 {/* Recent Deposits */}
        //                 <Grid item xs={12} md={4} lg={3}>
        //                     <Paper p={2} display={'flex'} flexDirection={'column'} height={'240'}>
        //                         weather{/* <Deposits /> */}
        //                     </Paper>
        //                 </Grid>
        //                 {/* Recent Orders */}
        //                 <Grid item xs={12}>
        //                     <Paper p={2} display={'flex'} flexDirection={'column'}>
        //                         summary{/* <Orders /> */}
        //                     </Paper>
        //                 </Grid>
        //             </Grid>
        //             {/* <Copyright sx={{ pt: 4 }} /> */}
        //         </Container>
        //     </Box>
        // </Box>

    )
}