import buoy from "./marine/buoy-icon-20.jpg";
import wreck from "./marine/ship-wreck.png";
import rock from "./marine/rock.png";
import waypoint from 'leaflet/dist/images/marker-icon.png';
const images = {
    buoy,
    waypoint,
    wreck,
    rock
}

function makeIcon(img, size) {
    var icon = {
        img: img,
        size: size
    };
    return icon;
}
export function getImage(name) {

    switch (name) {
        case 'buoy':
            return makeIcon(images.buoy, [26, 26]);
        case 'wreck':
            return makeIcon(images.wreck, [26, 26]);
        case 'rock':
            return makeIcon(images.rock, [26, 26]);
        case 'waypoint':
            return makeIcon(images.waypoint,);
        default:
            return makeIcon(images.waypoint,);

    }

}